<script setup>
import SnackBar from '@/components/widgets/SnackBar.vue'
import { useSnackBarStore } from '@/stores/snackbar'
import { useThemeConfig } from '@core/composable/useThemeConfig'
import { hexToRgb } from '@layouts/utils'
import { useTheme } from 'vuetify'
import { useAccountStore } from "@/stores/account"
import { lsGet, lsRemove } from "@/helpers/localStorageHelper"
import { onMounted } from "vue"
import { useRouter } from "vue-router"

const route = useRoute()
const router = useRouter()
const notification = useSnackBarStore()
const accountStore = useAccountStore()

const app_loaded = ref(false)

const {
  syncInitialLoaderTheme,
  syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme,
  isAppRtl,
} = useThemeConfig()

const { global } = useTheme()

// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme()
syncConfigThemeWithVuetifyTheme()

onMounted(()=>{
  getAccountInfo()
})

async function getAccountInfo() {
  if( lsGet('accessToken') ) {
    await accountStore.getInfo()
      .then(response => {
        window.app_configs = response.data.app_configs
        accountStore.afterloggedin(response)
        document.getElementById('loading-bg')?.remove()
        if (window.app_configs?.user?.role?.name === 'POS') {
          router.push('/pos')
        }
      })
      .catch(error => {
        notification.responseError(error, () => {
          setTimeout(() => {
            lsRemove(["accessToken", "logged", "userData", "permission"])
            router.push('/login')
          }, 500)
        })
      })
      .finally(() => {
        app_loaded.value = true
      })
  } else {
    app_loaded.value = true
    router.replace(route.query.to ? String(route.query.to) : "/login")
  }
}
</script>

<template>
  <VLocaleProvider
    v-if="!!app_loaded"
    :rtl="isAppRtl"
  >
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp
      :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`"
    >
      <SnackBar
        v-for="ntf of notification.getNotifications"
        :id="ntf.id"
        :key="'sn' + ntf.id"
        :show="ntf.show"
        :location="ntf.location"
        :icon="ntf.icon"
        :message="ntf.message"
        :timeout="ntf.timeout"
        :type="ntf.type"
        :color="ntf.color"
      />
      <RouterView />
    </VApp>
  </VLocaleProvider>
</template>
