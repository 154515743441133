<template>
  <TextField
    :type="!showPassword ? 'password' : 'text'"
    :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
    v-bind="$attrs"
    @click:append-inner="showPassword = !showPassword"
  >
    <slot />
  </TextField>
</template>

<script setup>
import TextField from "@/components/fields/TextField.vue";
import { ref } from "vue";

defineProps({
  required: { type: Boolean, default: false },
})

const showPassword = ref(false)
</script>

<style scoped></style>
