<template>
  <VRow>
    <VCol cols="12">
      <div class="branch-options">
        <template v-for="(branch, indx) in props.branches">
          <VBtn
            v-if="branch?.branch?.id"
            :key="indx"
            class="text-none option"
            :prepend-icon="selectedBranch && branch.branch_id === selectedBranch ? 'mdi-check' : ''"
            :class="selectedBranch && branch.branch_id === selectedBranch ? 'selected' : ''"
            :elevation="selectedBranch && branch.branch_id === selectedBranch ? 4 : 1"
            variant="outlined"
            @click="selectBranch(branch.branch_id)"
          >
            {{ branch.branch?.name }}
            {{ $t('stock')+': '+branch.quantity }}
          </VBtn>
        </template>
      </div>
    </VCol>
  </VRow>
</template>

<script setup>
import { computed } from "vue"

const props = defineProps({
  branches: {
    type: Array,
    required: true,
  },
  chosenBranch: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(["update:chosenBranch"])

const selectedBranch = computed({
  get: () => props.chosenBranch,
  set: value => {
    emit("update:chosenBranch", value)
  },
})

const selectBranch = branch => {
  selectedBranch.value = branch
}
</script>

<style lang="scss" scoped>
.branch-options {
  .option {
    padding: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    margin-block: 0.5rem;
    margin-inline: 0 0.5rem;
    transition: all 0.3s ease-in-out;

    &.selected {
      background-color: rgba(var(--v-theme-primary), 0.8);
      color: rgb(var(--v-theme-on-primary)) !important;
    }

    &.color {
      padding: 0;
      border-radius: 50%;
      margin: 0;
      block-size: 2rem;
      inline-size: 2rem;

      &.selected {
        border: 1px solid rgb(var(--v-theme-stroke));
      }

      .option-content {
        border-radius: 50%;
        block-size: 100%;
        inline-size: 100%;
      }
    }
  }
}
</style>
