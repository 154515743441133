<template>
    <!-- https://vue3datepicker.com/props/modes-configuration/ check preset range to replace the predefined date and range date to one component -->
  <VueDatePicker 
    v-model="range" 
    range 
    position="left"
    :dark="isDarkTheme"
    :enable-time-picker="false"
    :preset-dates="presetDates"
    @update:model-value="emit('selectDateRange', $event)"
  >
    <template #preset-date-range-button="{ label, value, presetDate }">
      <span 
          role="button"
          :tabindex="0"
          @click="presetDate(value)"
          @keyup.enter.prevent="presetDate(value)"
          @keyup.space.prevent="presetDate(value)">
        {{ label }}
      </span>
    </template>
  </VueDatePicker>
</template>

<script setup>
import RangeDateType from '@/constants/RangeDateType';
import { themeConfig } from '@themeConfig';
import VueDatePicker from '@vuepic/vue-datepicker';
import { computed, ref } from 'vue';

const props = defineProps({
  dateRange: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits(["changeRangeDate", "selectDateRange"])
const presetDates = ref(Object.values(RangeDateType));

const range = computed({
  get: () => props.dateRange,
  set: val => () => emit("selectDateRange", val),
})

const isDarkTheme = computed(()=>{
  return themeConfig.app.theme.value == 'dark'
})
</script>

<style>
.dp--menu-wrapper {
  background-color: rgb(var(--v-theme-background)) !important;
}

.dp__theme_light,
.dp__theme_dark {
  --dp-background-color: var(--v-theme-background) !important;
  --dp-text-color: rgba(var(--v-theme-on-surface), var(--v-medium-emphasis-opacity)) !important;
  --dp-border-color: var(--dp-secondary-color) !important;
}

.dp__menu,
.dp__arrow_top {
  border-color: var(--dp-secondary-color) !important;
}

.dp__input {
  padding-block: 9px;
}

</style>
