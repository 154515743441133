<template>
  <VRow class="section-title-container">
    <VCol
      v-if="enableIcon"
      cols="auto"
      class="align-self-center pr-0"
    >
      <slot name="prepend" />
    </VCol>
    <VCol
      class="align-self-center"
      :class="{'text-wrap': wrap}"
      cols="col"
    >
      <h3 class="section-title pt-1">
        {{ title }}
      </h3>
      <span
        v-if="props.subtitle"
        class="section-subtitle"
      >{{ props.subtitle }}</span>
    </VCol>
  </VRow>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    required: false,
    default: () => (''),
  },
  enableIcon: {
    type: Boolean,
    required: false,
    default: false,
  },
  wrap: {
    type: Boolean,
    required: false,
    default: false,
  },
})
</script>

<style scoped lang="scss">
.section-title-container {
  position: relative;
}

.section-title {
  font-size: 20px;
  font-weight: 500;
}

.section-subtitle {
  color: rgb(var(--v-theme-tertiary));
  font-size: 14px;
  font-weight: 400;
  margin-block-start: 5px;
}
</style>
