import { BaseUrl } from "@/helpers/BaseUrl"
import adminAxios from "@/plugins/axios"
import { defineStore } from "pinia"

const baseProductUrl = BaseUrl.get("product")

export const useProductStore = defineStore("product", {
  state: () => {
    return {
      products: [],
    }
  },
  getters: {
    getProducts() {
      return this.products
    },
  },
  actions: {
    async getProductsList(params) {
      try {
        const response = await adminAxios.get(`${baseProductUrl}/list`, {
          params,
        })

        this.products = response.data.data

        return response
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async getProductById(id, uuid) {
      let params = {
        uuid: uuid,
      }
      try {
        return await adminAxios.get(`${baseProductUrl}/${id}`, { params })
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async createProduct(payload) {
      try {
        const response = await adminAxios.post(`${baseProductUrl}`, payload)

        return !!response.data.success
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async updateProduct(id, uuid, payload) {
      try {
        const response = await adminAxios.post(
          `${baseProductUrl}/${id}?uuid=${uuid}`,
          payload,
        )

        return !!response.data.success
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async updateProductPrice(id, uuid, payload) {
      try {
        return await adminAxios.put(
          `${baseProductUrl}/${id}/price?uuid=${uuid}`,
          payload,
        )
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async updateProductStock(id, uuid, payload) {
      try {
        return await adminAxios.put(
          `${baseProductUrl}/${id}/stock?uuid=${uuid}`,
          payload,
        )
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async updateProductStatus(id, uuid, payload) {
      try {
        return await adminAxios.put(
          `${baseProductUrl}/${id}/status?uuid=${uuid}`,
          payload,
        )
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async delete(id, uuid) {
      try {
        return await adminAxios.delete(`${baseProductUrl}/${id}?uuid=${uuid}`)
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async duplicate(id, uuid) {
      try {
        return await adminAxios.post(`${baseProductUrl}/${id}/duplicate?uuid=${uuid}`)
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async getAllTags(params) {
      try {
        return await adminAxios.get(`${baseProductUrl}/tags`, { params })
      } catch (error) {
        return await Promise.reject(error)
      }
    },
  },
})
