<script setup>
const props = defineProps({
  cards: {
    type: Array,
    required: true,
    default: () => [],
  },
  selectedValue: { type: String, required: true, default: () => {} },
})
</script>

<template>
  <div>
    <VItemGroup mandatory>
      <VContainer>
        <VRow dense>
          <VCol
            v-for="(card, index) in props.cards"
            :key="index"
            cols="auto"
          >
            <VItem>
              <VCard
                class="d-flex align-center justify-center rounded-lg"
                :style="card.value == props.selectedValue ? 'border: 4px solid rgb(var(--v-theme-primary));' : null"
                :height="card.height"
                color="surface-one"
                :width="card.width"
                @click="$emit('toggle', card)"
              >
                <VFlex
                  :class="{ 'text-primary': card.value == props.selectedValue }"
                  class="text-center"
                >
                  <VIcon
                    :icon="card.icon"
                    height="30"
                    width="30"
                  />
                  <VCardTitle>
                    {{ card.title }}
                  </VCardTitle>
                  <VCardSubtitle style="white-space: normal;">
                    {{ card.subtitle }}
                  </VCardSubtitle>
                </VFlex>
              </VCard>
            </VItem>
          </VCol>
        </VRow>
      </VContainer>
    </VItemGroup>
  </div>
</template>
