const urls = {
  config: 'config/app/v1',
  account: 'account/v1',
  category: 'combo/category/v1',
  brand: 'combo/brand/v1',
  store: 'store/v1',
  storeBranch: 'store/branch/v1',
  user: 'account/user/v1',
  courier: 'account/courier/v1',
  driver: 'account/driver/v1',
  product: 'product/v1',
  ribbon: 'combo/ribbon/v1',
  tag: 'combo/tag/v1',
  variant: "combo/variant/v1",
  variationSource: "combo/variation/v1",
  unitCategory: "combo/unitCategory/v1",
  unitSize: "combo/unitSize/v1",
  locale: 'combo/locale/v1',
  currency: 'combo/currency/v1',
  country: 'combo/country/v1',
  warehouse: "store/warehouse/v1",
  role: "role/v1",
  attachment: "attachment/v1",
  attachmentGroup: "attachment/group/v1",
  location: "location/v1",
  locationZone: "location/zone/v1",
  homePage: "page/home/v1",
  webPage: "page/web/v1",
  userNotification: "notification/account/v1",
  sendNotification: "notification/send/v1",
  issue: "issue/v1",
  predefinedIssue: "issue/predefined/v1",
  order: "order/v1",
  orderNote: "order/note/v1",
  storeOrder: "order/store/v1",
  address: "address/account/v1",
  order_logistics: "order/logistic/v1",
  courier: "account/courier/v1",
  order_product: "order/product/v1",
  dashboard: "dashboard/v1",
  paymentMethod: "payment/method/v1",
  paymentProvider: "payment/provider/v1",
  shippingProvider: "shipping-provider/v1",
  shippingRule: "shipping-provider/rule/v1",
  export: "export/v1",
  import: "import/v1",
  promoCode: "promo/v1",
  searchEngine: "searchengine/v1",
  report: "report/v1",
  shippingProduct: "shipping-product/v1",
  shippingProductInfo: "shipping-product/info/v1",
  shippingReceivedProduct: "shipping-product/received/v1",
  shippingReceivedInfo: "shipping-product/received-info/v1",
  transferReceivedProduct: "shipping-product/transfer-product/v1",
  reportView: "report/view/v1",
}

export const BaseUrl = {
  get: name => urls[name],
}
