<script setup>
import { reactive } from "vue"
import i18n from "@/plugins/i18n"
import BaseDialog from "@/components/widgets/BaseDialog.vue"

const { t } = i18n.global

const data = reactive({
  visible: false,
  title: "title",
  message: "message",
})

const loading = ref(false)

let confirmResolve = () => {
  /* do nothing */
}

const agree = async () => {
  await confirmResolve(true)
}

const cancel = () => {
  confirmResolve(false)
  data.visible = false
}

const openDialog = (title, message) => {
  data.visible = true
  data.title = title
  data.message = message

  return new Promise(async resolve => {
    confirmResolve = async action => {
      loading.value = true
      await resolve(action)
      data.visible = false
      loading.value = false
    }
  })
}

defineExpose({ openDialog })
</script>

<template>
  <BaseDialog
    v-if="data.visible"
    :title="data.title"
    :loading="loading"
    @close="cancel"
    @save="agree"
  >
    <template #content>
      <VRow>
        <VCol>
          <p>{{ t(data.message) }}</p>
        </VCol>
      </VRow>
    </template>
  </BaseDialog>
</template>
