<template>
  <VBtnGroup
    v-model="value"
    mandatory
  >
    <VBtn
      v-for="tp in props.types"
      :key="tp.value"
      :value="tp.value"
      :color="tp.value === value ? 'primary' : 'secondary'"
      :class="tp.value === value ? 'text-white' : ''"
      :prepend-icon="tp.icon ? tp.icon : ''"
      class="text-capitalize"
      variant="flat"
      @click="value = tp.value"
    >
      {{ tp.label }}
    </VBtn>
  </VBtnGroup>
</template>

<script setup>
import { computed } from "vue"

const props = defineProps({
  types: { type: Array, required: true },
  value: { type: [Number, String], default: () => '' },
})

const emit = defineEmits(["newValue"])

const value = computed({
  get: () => props.value,
  set: value => emit("newValue", value),
})
</script>
