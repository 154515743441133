<template>
  <VAutocomplete
    v-model="value"
    :items="stores"
    item-value="id"
    item-title="name"
    :prefix="is_focused || value ? '' : $t('store')"
    variant="outlined"
    density="compact"
    color="primary"
    class="rounded-b"
    style="min-width: 220px;"
    :bg-color="$vuetify.theme.current.colors.surface"
    prepend-inner-icon="mdi-store"
    hide-details
    :loading="loading"
    :no-data-text="props.loading ? $t('loadingdots') : $vuetify.noDataText"
    @update:focused="onFocusInput"
  />
</template>

<script setup>
import i18n from "@/plugins/i18n";
import { useConfigStore } from "@/stores/config";
import { computed } from "vue";

const props = defineProps({
  storeId: {
    type: [Number, String],
    default: '',
  },
  allStores: {
    type: Boolean,
    default: true,
  },
  filterFn: {
    type: Function,
    default: ()=>{return true},
  },
})

const emit = defineEmits(["changeStore"])

const { t } = i18n.global
const loading = ref(false)
const is_focused = ref(false)
const config  = useConfigStore()

const value = computed({
  get: () => props.storeId,
  set: val => emit("changeStore", val),
})

const stores = ref([])

onMounted(()=>{
  let cached_stores = config.getConfigByKey('stores')
  if( props.filterFn ) {
    cached_stores = cached_stores.filter(s => props.filterFn(s))
  }
  stores.value = props.allStores ? [{ id: 0, name: t('all') }, ...cached_stores] : cached_stores
})

const onFocusInput = focused => {
  is_focused.value = focused
}
</script>
