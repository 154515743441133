import { BaseUrl } from "@/helpers/BaseUrl"
import adminAxios from "@/plugins/axios"
import { defineStore } from "pinia"

const baseDashboardUrl = BaseUrl.get("dashboard")

export const useDashboardStore = defineStore("dashboard", {
  state: () => {
    return {}
  },
  getters: {},
  actions: {
    async getDashboardData(params) {
      try {
        return await adminAxios.get(`${baseDashboardUrl}/all`, { params })
      } catch (error) {
        return await Promise.reject(error)
      }
    },
  },
})
