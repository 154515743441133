import AddNewButton from "@/components/button/AddNewButton.vue"
import CancelButton from "@/components/button/CancelButton.vue"
import CloseButton from "@/components/button/CloseButton.vue"
import DeleteButton from "@/components/button/DeleteButton.vue"
import EditButton from "@/components/button/EditButton.vue"
import MenuButton from "@/components/button/MenuButton.vue"
import PrimaryButton from "@/components/button/PrimaryButton.vue"
import SwitchGroupButton from "@/components/button/SwitchGroupButton.vue"
import ClickableGroupCards from "@/components/card/ClickableGroupCards.vue"
import SectionCard from "@/components/card/SectionCard.vue"
import FormAlert from "@/components/common/FormAlert.vue"
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue"
import PasswordField from "@/components/fields/PasswordField.vue"
import PlusMinusField from "@/components/fields/PlusMinusField.vue"
import TextArea from "@/components/fields/TextArea.vue"
import TextField from "@/components/fields/TextField.vue"
import LabelField from "@/components/label/LabelField.vue"
import LabelValue from "@/components/label/LabelValue.vue"
import SearchItemsTable from "@/components/search/SearchItemsTable.vue"
import Autocomplete from "@/components/selectors/Autocomplete.vue"
import Selection from "@/components/selectors/Selection.vue"
import SectionTitle from "@/components/title/SectionTitle.vue"
import BaseDialog from "@/components/widgets/BaseDialog.vue"
import DataTable from "@/components/widgets/DataTable.vue"
import DynamicDialog from "@/components/widgets/DynamicDialog.vue"
import ProgressBar from "@/components/widgets/ProgressBar.vue"
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import Vue3EasyDataTable from "vue3-easy-data-table"

export const installComponents = app => {
  app.component("EasyDataTable", Vue3EasyDataTable)
  app.component("DataTable", DataTable)
  app.component("ConfirmationDialog", ConfirmationDialog)
  app.component("BaseDialog", BaseDialog)
  app.component("ProgressBar", ProgressBar)
  app.component("DynamicDialog", DynamicDialog)
  app.component("CancelButton", CancelButton)
  app.component("PrimaryButton", PrimaryButton)
  app.component("AddNewButton", AddNewButton)
  app.component("CloseButton", CloseButton)
  app.component("EditButton", EditButton)
  app.component("DeleteButton", DeleteButton)
  app.component("FormAlert", FormAlert)
  app.component('VueDatePicker', VueDatePicker)
  app.component('Autocomplete', Autocomplete)
  app.component('SectionCard', SectionCard)
  app.component('SwitchGroupButton', SwitchGroupButton)
  app.component('ClickableGroupCards', ClickableGroupCards)
  app.component('PasswordField', PasswordField)
  app.component('LabelField', LabelField)
  app.component('SectionTitle', SectionTitle)
  app.component('TextField', TextField)
  app.component('LabelValue', LabelValue)
  app.component("SearchItemsTable", SearchItemsTable)
  app.component("Selection", Selection)
  app.component("TextArea", TextArea)
  app.component("PlusMinusField", PlusMinusField)
  app.component("MenuButton", MenuButton)
}
