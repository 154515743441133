<script setup>
import ExportAttributeMenu from '@/components/export/ExportAttributeMenu.vue'
import adminAxios from "@/plugins/axios"
import i18n from "@/plugins/i18n"
import { useAppStore } from '@/stores/app'
import { useSnackBarStore } from '@/stores/snackbar'
import { onMounted, reactive, ref, watch } from "vue"
import { useRouter } from "vue-router"

// Props
const mainProps = defineProps({
  baseRoute: { type: [String], required: true },
  headers: { type: Array, default: ()=>[] },
  paginated: { type: [Boolean], default: true },
  enableFilters: { type: [Boolean], default: false },
  editRecordUrl: { type: [Boolean], default: false },
  hasAddBtn: { type: [Boolean], required: false, default: true },
  exportModel: { type: String, default: null },
  rows: { type: Array,default: null },
  withSearch: { type:Boolean, default: true },
  filtersData: { type: Object, default: () => ({}) },
  defaultObject: { type: Object, default: () => ({}) },
  searchLabel: { type: String, default:'searchHere' },
  tTitle: { type: String, default:null },
  tSubtitle: { type: String, default:null },
  title: { type: String, default:null },
  subtitle: { type: String, default:null },
  orderBy: { type: String, default:'id' },
  sortType: { type: String, default:'desc' },
  beforeLoad: { type: Function, default: () => true },
  showFilters: { type: Boolean, default: false },
  beforeApplyItemns: { type: Function, default: null },
  enableUrlParams: { type: Boolean, default: false },
  setFilterFromUrl: { type: Function, default: null },
})

const emit = defineEmits(["addButtonAction", "isEmpty", "dataLoaded"])
const { t } = i18n.global

const router = useRouter()

const app = useAppStore()
const notification = useSnackBarStore()

// Data
const data = reactive({
  pagination: {
    total: 0,
    count: 0,
    totalPages: 1,
  },
  search: "",
  loading: false,
  loaded: false,
  items: [],
  openDeleteDialog: false,
  showFilters: mainProps.showFilters,
  isMounted: false,
})

const pageField = ref()
const itemsTimer = ref(null)

// const confirmDeleteDialog = ref<InstanceType<typeof ConfirmDialog>>();
const tablePagination = ref({
  page: 1, // Current Page
  sortBy: mainProps.orderBy,
  sortType: mainProps.sortType,
  rowsPerPage: 25,
})

const updatedUrlParams = computed(() => app.getUpdateUrlParams)

onMounted(() => {
  onReloadDataTable()
})

const onReloadDataTable = () => {
  data.isMounted = false
  
  if (mainProps.rows != null) {
    data.items = mainProps.rows
    data.pagination.total = mainProps.rows.length
  }

  if (mainProps.enableUrlParams && Object.keys(router.currentRoute.value.query).length) {
    let params = router.currentRoute.value.query
    if (params?.descending) {
      tablePagination.value.sortType = params.descending ? 'desc' : 'asc'
    }
    if (params?.sort) {
      tablePagination.value.sortBy = params.sort
    }
    if (params?.page) {
      tablePagination.value.page = parseInt(params?.page)
    }
    if (params?.search) {
      data.search = params?.search
    }

    if (mainProps.setFilterFromUrl) {
      let filters = { ...mainProps.filtersData }
      Object.keys(filters).map(key=>{
        if (params[key]) {
          filters[key] = is_numeric(params[key]) ? parseInt(params[key]) : params[key]
        }
      })
      mainProps.setFilterFromUrl(filters)
    }
  }

  setTimeout(() => {
    data.isMounted = true
    getData()
  }, 100)
}

// Methods

const getItems = () => {
  return data.items
}

const getData = async () => {
  if (!data.isMounted) {
    return
  }

  if (mainProps.rows != null) {
    tablePagination.value.rowsPerPage = -1
    
    return
  }

  if (mainProps.beforeLoad() === false) {
    return
  }
  
  data.loading = true

  const params = {
    page: tablePagination.value.page,
    per_page: tablePagination.value.rowsPerPage,
    descending: tablePagination.value.sortType == 'desc' ? true : false,
    sort: tablePagination.value.sortBy,
    search: null,
    ...mainProps.defaultObject,
  }

  if (data.search && data.search.length > 0) {
    params.search = data.search
  }
  
  // Filters
  if ( mainProps.filtersData ) {
    for (const key in mainProps.filtersData) {
      if (mainProps.filtersData[key] !== null && mainProps.filtersData[key] !== "") {
        params[key] = mainProps.filtersData[key]
      }
    }
  }

  if (mainProps.enableUrlParams) {
    router.push({ path: router.currentRoute.value.fullPath, query: Object.filter(params, val => !!val) })
  }

  adminAxios
    .get(mainProps.baseRoute, {
      params,
    })
    .then(response => {
      const result = response.data

      data.items = mainProps.beforeApplyItemns ? mainProps.beforeApplyItemns(result.data) : result.data
      if (result) {
        data.pagination.total = result.total
        data.pagination.count = result.per_page
        data.pagination.totalPages = result.last_page
        tablePagination.value.rowsPerPage = result.per_page
        tablePagination.value.page = result.current_page
        emit("dataLoaded", result.data)
      }
    })
    .catch(notification.responseError)
    .finally(() => {
      data.loading = false
      data.loaded = true
      emit("isEmpty", data.items.length === 0)
    })
}

const paginationRange = (inputPage, lastPage) => {
  if (inputPage && inputPage >= 1 && inputPage <= lastPage) return true
  
  return lastPage
}

const onSearch = () => {
  if (itemsTimer.value) {
    clearTimeout(itemsTimer.value)
  }
  itemsTimer.value = setTimeout(() => {
    tablePagination.value.page = 1
    getData()
  }, 700)
}

// watchers
watch(
  () => mainProps.filtersData,
  async value => {
    if (data.isMounted && !data.loading) {
      tablePagination.value.page = 1
      await getData()
    }
  },
  { deep: true },
)

watch(
  tablePagination,
  async value => {
    if (data.isMounted && paginationRange(value.page, data.pagination.totalPages) === true && !data.loading) {
      await getData()
    }
  },
  { deep: true },
)

watch(
  updatedUrlParams,
  value => {
    setTimeout(() => {
      onReloadDataTable()
    }, 100)
  },
  { deep: true },
)

defineExpose({ getData, getItems })
</script>

<template>
  <VCard class="data-table">
    <VRow
      class="ma-2"
      dense
      align="center"
    >
      <VCol
        v-if="mainProps.title || mainProps.tTitle"
        cols="auto"
      >
        <h3>
          {{ mainProps.tTitle || t(mainProps.title) }}
          <VTooltip
            location="top"
          >
            <template #activator="{ props }">
              <VBtn
                icon="mdi-refresh"
                color="default"
                variant="text"
                v-bind="props"
                class="ml-2"
                size="small"
                @click="getData"
              />
            </template>
            <span>{{ $t("refresh") }}</span>
          </VTooltip>

          <ExportAttributeMenu 
            v-if="mainProps.exportModel"
            :export-model="mainProps.exportModel"
            :data-object="mainProps.filtersData"
            :search="data.search"
          />
          
          <slot name="afterReload" />
        </h3>
        <span
          v-if="mainProps.tSubtitle || mainProps.subtitle"
          class="subtitle"
        >{{ mainProps.tSubtitle || t(mainProps.subtitle) }}</span>
      </VCol>
      <VSpacer />
      <VCol cols="auto">
        <slot name="beforeSearch" />
      </VCol>
      <VCol
        v-if="mainProps.withSearch"
        cols="auto"
      >
        <VTextField
          v-model="data.search"
          variant="outlined"
          append-inner-icon="mdi-magnify"
          color="primary"
          :placeholder="$t(mainProps.searchLabel || 'searchHere')"
          hide-details
          density="compact"
          clearable
          class="max-width-350 ma-2"
          style="width: 250px;"
          @update:model-value="onSearch"
        />
      </VCol>
      <VCol
        cols="auto"
      >
        <slot name="buttons" />
      </VCol>
      <VCol
        v-if="mainProps.enableFilters"
        cols="auto"
      >
        <VTooltip location="top">
          <template #activator="{ props }">
            <VBtn
              icon="mdi-filter-outline"
              variant="tonal"
              density="compact"
              color="default"
              v-bind="props"
              @click="data.showFilters = !data.showFilters"
            />
          </template>
          <span>{{ $t("filters") }}</span>
        </VTooltip>
      </VCol>
    </VRow>
    <VExpandTransition v-if="mainProps.enableFilters && data.showFilters">
      <VCard
        class="ma-2"
        elevation="1"
        style="overflow: initial;"
      >
        <slot name="filters" />
      </VCard>
    </VExpandTransition>
    <VRow class="mb-2">
      <VCol cols="12">
        <EasyDataTable
          v-model:server-options="tablePagination"
          :hide-footer="!mainProps.paginated"
          :theme-color="$vuetify.theme.current.colors.primary"
          :headers="mainProps.headers"
          :items="data.items"
          :server-items-length="data.pagination.total"
          :loading="data.loading"
          table-class-name="customize-table"
        >
          <template
            v-for="(x, slotName) in $slots"
            #[slotName]="item"
          >
            <slot
              v-if="slotName.includes('item-')"
              :name="slotName"
              v-bind="item"
            />
          </template>
          <template #pagination>
            <VTextField
              ref="pageField"
              v-model.number="tablePagination.page"
              variant="solo"
              class="mx-2"
              style="max-width: 150px;"
              :prefix="$t('page') + ': '"
              :rules="[paginationRange(tablePagination.page, data.pagination.totalPages)]"
            />
            <VPagination
              v-model="tablePagination.page"
              size="small"
              :length="data.pagination.totalPages"
              :disabled="data.loading"
              :total-visible="5"
              elevation="1"
            />
          </template>
        </EasyDataTable>
      </VCol>
    </VRow>
  </VCard>
</template>

<style lang="scss">
.customize-table {
  --da0d4328: 350px !important;
  --easy-table-border: thin solid rgba(0, 0, 0, 12%);
  --easy-table-row-border: thin solid rgba(0, 0, 0, 12%);
  --easy-table-header-font-size: 1rem;
  --easy-table-header-height: 55px;
  --easy-table-header-font-color: rgb(var(--v-theme-data-table-header-text-color));
  --easy-table-header-background-color: rgb(var(--v-theme-inner-background));
  --easy-table-header-item-padding: 10px 15px;
  --easy-table-body-row-font-color: rgb(var(--v-theme-data-table-primary-text-color));
  --easy-table-body-row-background-color: rgb(var(--v-theme-data-table-primary-bg-color));
  --easy-table-body-even-row-font-color: rgb(var(--v-theme-data-table-secondary-text-color));
  --easy-table-body-even-row-background-color: rgb(var(--v-theme-data-table-secondary-bg-color));
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 0.9rem;
  --easy-table-body-row-hover-font-color: rgb(var(--v-theme-data-table-hover-text-color));
  --easy-table-body-row-hover-background-color: rgb(var(--v-theme-data-table-hover-bg-color));
  --easy-table-body-item-padding: 0 15px;
  --easy-table-footer-background-color: rgb(var(--v-theme-data-table-primary-bg-color));
  --easy-table-footer-font-color: rgb(var(--v-theme-data-table-header-text-color));
  --easy-table-footer-font-size: 0.85rem;
  --easy-table-footer-padding: 0 10px;
  --easy-table-footer-height: 50px;
  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;
  --easy-table-scrollbar-corner-color: #2d3a4f;
  --easy-table-loading-mask-background-color: #2d3a4f;
}

.vue3-easy-data-table {
  border-block-end: rgb(var(--easy-table-border));
  border-block-start: rgb(var(--easy-table-border));
  border-inline-end: none !important;
  border-inline-start: none !important;
}

.customize-table .header-text {
  font-weight: 600;
}

.data-table-action-button-list .v-list-item__prepend > .v-icon {
  margin-inline-end: 13px;
}

.vue3-easy-data-table__header th.sortable.none .sortType-icon {
  opacity: 0.35 !important;
}

.data-table .subtitle {
  color: rgb(var(--v-theme-text-secondary));
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.vue3-easy-data-table__header,
vue3-easy-data-table__body {
  z-index: 0;
}
</style>
