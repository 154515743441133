import { BaseUrl } from '@/helpers/BaseUrl'
import { lsGet, lsRemove, lsSet } from "@/helpers/localStorageHelper"
import adminAxios from '@/plugins/axios'
import { defineStore } from "pinia"

const accountBaseUrl = BaseUrl.get("account")
const userBaseUrl = BaseUrl.get("user")
const driverBaseUrl = BaseUrl.get("driver")
const courierBaseUrl = BaseUrl.get("courier")

export const useAccountStore = defineStore("account", {
  state: () => {
    return {
      admin: lsGet("admin", true) || null,
      logged: lsGet("logged", true) || false,
    }
  },
  getters: {
    getAdmin() {
      return this.admin
    },
    isLoggedIn() {
      return this.logged
    },
  },
  actions: {
    async login(payload) {
      try {
        return await adminAxios.post(`${accountBaseUrl}/login`, payload) 
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async forgetPassword(payload) {
      try {
        return await adminAxios.post(`${accountBaseUrl}/forgot`, payload)
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async resetPassword(payload) {
      try {
        const response = await adminAxios.post(`${accountBaseUrl}/reset`, payload)
        
        return this.afterloggedin(response)
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async changePassword(payload) {
      try {
        return await adminAxios.put(`${accountBaseUrl}/profile`, payload)
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async getInfo() {
      try {
        return await adminAxios.get(`${accountBaseUrl}/info?with_config=1`)
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    afterloggedin(response) {
      this.admin = response.data.data
      
      this.logged = true
      
      lsSet("permission", JSON.stringify(this.admin?.role?.permissions))
      lsSet("accessToken", this.admin.api_token)
      lsSet("logged", true)
      lsSet("userData", this.admin, true)

      if( !lsGet('supported_country_id') ) {
        lsSet("supported_country_id", window.app_configs?.default_country_id || this.admin.account?.country_id)
        
      }

      if( !lsGet('currency') && window.app_configs ) {
        lsSet('currency', window.app_configs?.currency ?? '$')
      }
      
      return response
    },
    async logout() {
      try {
        const response = await adminAxios.post(`${accountBaseUrl}/logout`)
        
        this.logged = false
        this.admin = null
        lsRemove(["accessToken", "logged", "userData", "permission"])

        return response
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async createNewAccount(payload, type) {
      try {
        if (type == "user") {
          return await adminAxios.post(`${userBaseUrl}`, payload)
        } else if (type == "admin") {
          return await adminAxios.post(`${accountBaseUrl}`, payload)
        } else if (type == "driver") {
          return await adminAxios.post(`${driverBaseUrl}`, payload)
        }
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async editAccountDetails(payload, type, accountId, uuid) {
      try {
        if (type == "user") {
          return await adminAxios.post(`${userBaseUrl}/${accountId}?uuid=${uuid}`, payload)
        } else if (type == "admin") {
          return await adminAxios.post(`${accountBaseUrl}/${accountId}?uuid=${uuid}`, payload)
        } else if (type == "driver") {
          return await adminAxios.post(`${driverBaseUrl}/${accountId}?uuid=${uuid}`, payload)
        } else if (type == "profile") {
          return await adminAxios.post(`${accountBaseUrl}/profile?uuid=${uuid}`, payload)
        }
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async getAccountList(type, params) {
      try {
        if (type == "user") {
          return await adminAxios.get(`${userBaseUrl}/list`, { params })
        } else if (type == "admin") {
          return await adminAxios.get(`${accountBaseUrl}/list`, { params })
        } else if (type == "driver") {
          return await adminAxios.get(`${driverBaseUrl}/list`, { params })
        }
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async getUsersList(params) {
      try {
        return await adminAxios.get(`${userBaseUrl}/list`, { params })
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async updateUserDiscountPer(id, params) {
      try {
        return await adminAxios.put(`${userBaseUrl}/${id}/discount-per`, params)
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async updateUserWallet(id, params) {
      try {
        return await adminAxios.put(`${userBaseUrl}/${id}/wallet`, params)
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async getAccountDetails(id, type) {
      try {
        if (type == "user") {
          return await adminAxios.get(`${userBaseUrl}/${id}`)
        } else if (type == "admin") {
          return await adminAxios.get(`${accountBaseUrl}/${id}`)
        } else if (type == "driver") {
          return await adminAxios.get(`${driverBaseUrl}/${id}`)
        }
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async deleteAccount(id, uuid, type) {
      try {
        if (type == "user") {
          return await adminAxios.delete(`${userBaseUrl}/${id}?uuid=${uuid}`)
        } else if (type == "admin") {
          return await adminAxios.delete(`${accountBaseUrl}/${id}?uuid=${uuid}`)
        } else if (type == "driver") {
          return await adminAxios.delete(`${driverBaseUrl}/${id}?uuid=${uuid}`)
        }
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async toggleStatus(id, payload) {
      try {
        return await adminAxios.put(`${userBaseUrl}/${id}/active`, payload)
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async updateUserType(id, payload) {
      try {
        return await adminAxios.put(`${userBaseUrl}/${id}/type`, payload)
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async getAllAccounts(type = "user") {
      try {
        if (type == "user") {
          return await adminAxios.get(`${userBaseUrl}/all`)
        } else if (type == "admin") {
          return await adminAxios.get(`${accountBaseUrl}/all`)
        } else if (type == "driver") {
          return await adminAxios.get(`${driverBaseUrl}/all`)
        } else if (type == "courier") {
          return await adminAxios.get(`${courierBaseUrl}/all`)
        }
      } catch (error) {
        return await Promise.reject(error)
      }
    },
  },
})
