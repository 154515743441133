<script setup>
import i18n from "@/plugins/i18n"

const props = defineProps({
  totalOrders: {
    type: Number,
    default: 0,
  },
  totalCustomers: {
    type: Number,
    default: 0,
  },
  totalActiveOrders: {
    type: Number,
    default: 0,
  },
  totalActiveIssues: {
    type: Number,
    default: 0,
  },
})

const { t } = i18n.global

const websiteAnalytics = ref(
  {
    name: 'Traffic',
    data: [
      {
        number: props.totalOrders,
        text: t('orders'),
      },
      {
        number: props.totalCustomers,
        text: t('customers'),
      },
      {
        number: props.totalActiveOrders,
        text: t('activeOrders'),
      },
      {
        number: props.totalActiveIssues,
        text: t('activeIssues'),
      },
    ],
  })
</script>

<template>
  <VCard color="primary">
    <VCardText>
      <VRow>
        <VCol cols="12">
          <h6 class="text-h6 text-white mb-1">
            {{ t('websiteAnalytics') }}
          </h6>
          <p class="text-sm mb-0">
            Based on Total {{ props.totalOrders }} Order(s)
          </p>
        </VCol>

        <VCol
          cols="12"
        >
          <p class="font-weight-semibold">
            {{ websiteAnalytics.name }}
          </p>
        </VCol>
        <VCol
          v-for="d in websiteAnalytics.data"
          :key="d.number"
          cols="6"
          class="text-no-wrap"
        >
          <VChip
            label
            class="me-2"
          >
            {{ d.number }}
          </VChip>
          <span>{{ d.text }}</span>
        </VCol>
      </VRow>
    </VCardText>
  </VCard>
</template>

<style lang="scss">
.card-website-analytics-img {
  block-size: 160px;
}

@media screen and (min-width: 600px) {
  .card-website-analytics-img {
    position: absolute;
    margin: auto;
    inset-block-end: 40px;
    inset-block-start: -1rem;
    inset-inline-end: 1rem;
  }
}

.web-analytics-carousel {
  .v-carousel__controls {
    .v-btn:not(.v-btn--active) {
      opacity: 0.4;
    }
  }
}
</style>

