import { BaseUrl } from "@/helpers/BaseUrl"
import { lsGet } from "@/helpers/localStorageHelper"
import adminAxios from "@/plugins/axios"
import { defineStore } from "pinia"

const exportUrl = BaseUrl.get("export")

export const useExportStore = defineStore("export", {
  state: () => {
    return {}
  },
  getters: {},
  actions: {
    downloadById(id) {
      let base_url = import.meta.env.VITE_API_URL
      let country_id = lsGet("supported_country_id")
      let api_token = lsGet("accessToken")
      window.open(base_url + `/${exportUrl}/download?id=${id}&key_hash=` + api_token + "&scountryid=" + country_id, "_blank")
    },
    async downloadDoc(id) {
      try {
        return await adminAxios.get(`${exportUrl}/download?id=${id}`, {
          responseType: "blob",
        })
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async getExportableAttributes(exportModel, filter) {
      const params = {}
      if (filter) {
        params.filter = filter
      }
      params.model = exportModel
      try {
        return await adminAxios.get(`${exportUrl}/attributes`, { params })
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async export(exportModel, attributes, dataObject = {}, filter = null) {
      const body = {
        model: exportModel,
        attributes,
        filter,
        ...dataObject,
      }

      try {
        return await adminAxios.post(`${exportUrl}`, body)
      } catch (error) {
        return await Promise.reject(error)
      }
    },
  },
})
