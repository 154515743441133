<template>
  <VRow
    class="pa-0 ma-0 align-center"
    :class="`theme-${props.styleNumber}`"
  >
    <VCol
      cols="auto"
      class="pa-0"
    >
      <VBtn
        icon="mdi-minus"
        color="primary"
        variant="tonal"
        :density="getButtonDensity"
        :disabled="props.value <= props.min"
        class="left-btn"
        elevation="24"
        border
        :size="props.btnSize"
        @click="minus"
      />
    </VCol>
    <VCol
      cols="auto"
      class="pa-0"
    >
      <TextField
        :model-value="props.value"
        class="rounded-0 input-quantity-field small-text-field"
        :rules="rules"
        type="number"
        :density="getTextDensity"
        hide-details
        @input="handleInput"
      />
    </VCol>
    <VCol
      cols="auto"
      class="pa-0"
    >
      <VBtn
        icon="mdi-plus"
        color="primary"
        variant="tonal"
        :density="getButtonDensity"
        :disabled="props.value >= props.max"
        class="right-btn"
        elevation="24"
        border
        :size="props.btnSize"
        @click="plus"
      />
    </VCol>
  </VRow>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  value: {
    type: Number,
    default: 1,
  },
  min: {
    type: Number,
    default: 1,
  },
  max: {
    type: Number,
    default: 100,
  },
  step: {
    type: Number,
    default: 1,
  },
  styleNumber: {
    type: Number,
    default: 1,
  },
  btnSize: {
    type: String,
    required: false,
    default: "small",
  },
})

const emit = defineEmits(['update:value'])

const getTextDensity = computed(() => {
  if (props.styleNumber === 1) {
    return "comfortable"
  } else {
    return "compact"
  }
})

const getButtonDensity = computed(() => {
  if (props.styleNumber === 1) {
    return "comfortable"
  } else {
    return "compact"
  }
})

const rules = [
  v => !!v || "Quantity is required",
  v => v >= props.min || `Quantity must be greater than or equal to ${props.min}`,
  v => v <= props.max || `Quantity must be less than or equal to ${props.max}`,
]

const plus = (event) => {
  event.preventDefault()
  if (props.value + props.step <= props.max) {
    emit('update:value', parseInt(props.value + props.step))
  }
}

const handleInput = event => {
  event.preventDefault()
  let value = parseInt(event.target.value)
  if(isNaN(value)) value = 0
  if(value <= props.min){
    value = props.min
  } 
  if(value >= props.max) {
    value = props.max
  }
  emit('update:value', parseInt(value))
}

const minus = (event) => {
  event.preventDefault()
  if (props.value - props.step >= props.min) {
    emit("update:value", parseInt(props.value - props.step))
  }
}
</script>

<style lang="scss">
.theme-1 {
  .input-quantity-field {
    margin-inline: 5px;
    text-align: center;
  }

  .input-quantity-field,
  .input-quantity-field input,
  .input-quantity-field .v-field__field {
    text-align: center;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  margin: 0;
  appearance: none;
}

input[type="number"] {
  padding-block: 0 !important;
}

.small-text-field {
  inline-size: 70px;
}

.theme-2 {
  .left-btn,
  .right-btn {
    block-size: 38px;
    inline-size: 40px;
  }

  .left-btn {
    border: 1px solid rgb(var(--v-border-color));
    border-end-end-radius: 0;
    border-start-end-radius: 0;
    margin-inline-end: -5px;
  }

  .right-btn {
    border: 1px solid rgb(var(--v-border-color));
    border-end-start-radius: 0;
    border-start-start-radius: 0;
    margin-inline-start: -5px;
  }

  .input-quantity-field {
    position: relative;
    z-index: 9999;
    text-align: center;
  }

  .input-quantity-field,
  .input-quantity-field input,
  .input-quantity-field .v-field__field {
    background: rgb(var(--v-theme-background));
    text-align: center;
  }

  .input-quantity-field .v-field__outline__start,
  .input-quantity-field .v-field__outline__end {
    border-radius: 0 !important;
  }
}
</style>
