function lsSet(key, value, parse = false) {
  if (parse) {
    value = JSON.stringify(value)
  }
  localStorage.setItem(key, value)
}

function lsGet(key, parse = false) {
  if (!parse) {
    return localStorage.getItem(key)
  } else {
    return JSON.parse(localStorage.getItem(key))
  }
}

function lsRemove(keys) {
  if (keys.length > 0) {
    for (let i = 0; i < keys.length; i++) {
      localStorage.removeItem(keys[i])
    }
  } else {
    localStorage.clear()
  }
}

export { lsRemove, lsGet, lsSet }
