<template>
  <VBtn
    icon="mdi-dots-vertical"
    rounded="0"
    elevation="0"
    density="comfortable"
    :color="$vuetify.theme.current.colors.surface"
    v-bind="$attrs"
  />
</template>

<script setup></script>

<style lang="scss" scoped></style>
