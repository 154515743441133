<template>
  <VMenu
    v-bind="props"
    v-model="attributeMenu"
    min-width="150"
    bottom
    :close-on-content-click="false"
  >
    <template #activator="{ props: menu }">
      <VTooltip
        location="top"
      >
        <template #activator="{ props: tip }">
          <VBtn
            icon="mdi-launch"
            color="green"
            variant="text"
            size="small"
            v-bind="mergeProps(menu, tip)"
            @click="menu.click"
          />
        </template>
        <span>{{ $t("export") }}</span>
      </VTooltip>
    </template>
    <VCard>
      <VCardTitle>
        {{ t("exportOptions") }}
        <VBtn
          icon="mdi-launch"
          color="primary"
          variant="plain"
          :loading="data.attributesLoading || data.exportLoading"
          @click="exportExcel"
        />
      </VCardTitle>
      <VList>
        <VRow v-if="data.attributesLoading">
          <VProgressCircular
            class="mx-auto"
            indeterminate
            color="primary"
          />
        </VRow>

        <Draggable
          v-else
          v-model="data.attributes"
          item-key="name"
        >
          <template #item="{ element }">
            <VCheckbox
              v-model="element.selected"
              off-icon="radio_button_unchecked"
              on-icon="check_circle"
              color="primary"
              class="ml-5"
              @click.stop=""
            >
              <template #label>
                <div @click="item.selected = !element.selected">
                  {{ element.name }}
                </div>
              </template>
            </VCheckbox>
          </template>
        </Draggable>
      </VList>
    </VCard>
  </VMenu>
</template>

<script setup>
import { watch, reactive, mergeProps, ref } from "vue"
import i18n from "@/plugins/i18n"
import { useExportStore } from "@/stores/export"
import { useSnackBarStore } from "@/stores/snackbar"
import Draggable from "vuedraggable"

const props = defineProps({
  filter: {
    type: String,
    default: null,
  },
  search: {
    type: String,
    default: null,
  },
  dataObject: {
    type: Object,
    default: () => {},
  },
  mainButtonClass: {
    type: String,
    default: null,
  },
  exportModel: {
    type: String,
    default: null,
  },
})

const { t } = i18n.global

const exportStore = useExportStore()
const notification = useSnackBarStore()

const attributeMenu = ref(false)

const data = reactive({
  attributes: [],
  attributesLoading: false,
  exportLoading: false,
})

const getExportableAttributes = () => {
  data.attributesLoading = true
  exportStore
    .getExportableAttributes(props.exportModel, data.filter)
    .then(res => {
      data.attributes = Object.entries(res.data.data).map(attribute => {
        return {
          selected: true,
          attribute: attribute[0],
          name: attribute[1],
        }
      })
    })
    .finally(() => {
      data.attributesLoading = false
    })
}

const exportExcel = () => {
  const selectedAttributes = data.attributes.filter(a => a.selected).map(a => a.attribute)
  if (!selectedAttributes.length) {
    notification.error(t("exportAtLeastOne"))
    
    return
  }

  data.exportLoading = true
  let request = props.dataObject || {}
  request.search = props.search
  
  exportStore
    .export(props.exportModel, selectedAttributes, request, props.filter)
    .then(res => {
      attributeMenu.value = false
      notification.success(res.data.data)
    })
    .catch(notification.responseError)
    .finally(() => {
      data.exportLoading = false
    })
}

watch(
  attributeMenu,
  async value => {
    if (value === true) {
      await getExportableAttributes()
    }
  },
  { deep: true },
)
</script>

<style scoped>
:deep(div.v-input__details) {
  grid-area: unset !important;
}
</style>
