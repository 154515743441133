<script setup>
import PlusMinusField from "@/components/fields/PlusMinusField.vue"
import ProductBranchSelector from "@/components/product/ProductBranchSelector.vue"
import ProductImageSlider from "@/components/product/ProductImageSlider.vue"
import ProductVariantSelector from "@/components/product/ProductVariantSelector.vue"
import BaseDialog from "@/components/widgets/BaseDialog.vue"
import ProgressBar from "@/components/widgets/ProgressBar.vue"
import { useHelperFunctions } from "@/composables/useHelperFunctions"
import ProductCustomizationType from "@/constants/ProductCustomizationType"
import UserType from "@/constants/UserType"
import i18n from "@/plugins/i18n"
import { useProductStore } from "@/stores/product"
import DefaultProductPicture from '@images/placeholder.png'
import { computed, reactive, watch } from "vue"

const mprops = defineProps({
  selectedUser: {
    type: Object,
    required: false,
    default: ()=>({}),
  },
  defaultBranch: {
    type: Number,
    required: false,
    default: 0,
  },
})

const { formatPrice } = useHelperFunctions()
const product = useProductStore()
const { t } = i18n.global

const unlimitedStock = ref(999)

const data = reactive({
  visible: false,
  action: 'create',
  step: 0,
  quantity: 1,
  chosenProduct: null,
  variantCombination: [],
  customizations: [],
  stock: 0,
  stockThreshold: 0,
  price: 0,
  discounted_price: 0,
  wholesale_price: 0,
  distribution_price: 0,
  sku: "",
  openCustomization: false,
  outOfStockVariantCombinations: [],
  selectedBranch: mprops.defaultBranch||0,
})

const resetData = () => {
  data.step = 0
  data.chosenProduct = {}
  data.quantity = 1
  data.variantCombination = []
  data.customizations = []
  data.stock = 0
  data.stockThreshold = 0
  data.price = 0
  data.discounted_price = 0
  data.wholesale_price = 0
  data.distribution_price = 0
  data.sku = ""
  data.openCustomization = false
  data.outOfStockVariantCombinations = []
}

// Computed properties

const title = computed(() => {
  return data.action === 'create' ? "addProduct" : "editProduct"
})

const productImages = computed(() => {
  let images = []

  if (data.chosenProduct.images) {
    images = data.chosenProduct.images.map(image => {
      return image?.thumb_url
    })
  }

  if (data.chosenProduct.image) {
    images.unshift(data.chosenProduct.image?.thumb_url)
  } else {
    images.unshift(DefaultProductPicture)
  }

  // Remove duplicate images
  images = [...new Set(images)]

  return images
})

const isAddButtonDisabled = computed(() => {
  if (data.variantCombination && data.chosenProduct?.variants && data.chosenProduct.variants?.length !== data.variantCombination?.length) {
    return true
  }
  
  return data.stock <= 0
})

const customizationsPrice = computed(() => {
  let extraPrice = 0
  if (data.customizations) {
    data.customizations.forEach(customization => {
      if (customization.type === ProductCustomizationType.CHECKBOX) {
        customization.options.forEach(option => {
          if (option.selected) {
            extraPrice += option.price * (option.quantity || 1)
          }
        })
      } else if (customization.type === ProductCustomizationType.RADIO) {
        if (customization.value) {
          // get selected option
          const option = customization.options.find(option => option.id === customization.value)

          extraPrice += option.price * (option.quantity || 1)
        }
      } else if (customization.type === ProductCustomizationType.TEXT) {
        customization.options.forEach(option => {
          if (option.price !== 0 && option.selected && option.value) {
            extraPrice += option.price
          }
        })
      }
    })
  }
  
  return extraPrice
})

// Methods

const setProductInfo = async (id, uuid, productInfo = null) => {
  await product.getProductById(id, uuid).then(res => {
    data.chosenProduct = res.data.data
    data.step = 1

    onSelectProduct(productInfo)
  })
}

const onSelectProduct = (productInfo = null) => {
  data.stock = data.chosenProduct.quantity
  if( data.chosenProduct.ignore_stock ) {
    data.stock = data.chosenProduct.in_stock ? unlimitedStock.value : 0
  }

  data.image = data.chosenProduct?.image
  data.stockThreshold = data.chosenProduct.quantity_threshold
  data.price = data.chosenProduct.price
  data.discounted_price = data.chosenProduct.discounted_price
  data.wholesale_price = data.chosenProduct.wholesale_price
  data.distribution_price = data.chosenProduct.distribution_price
  data.sku = data.chosenProduct.sku
  data.options = []
  
  if (productInfo) {
    data.selectedBranch = productInfo.store_branch_id
    data.quantity = productInfo.quantity
    data.options = productInfo.options
    getVariantCombinationById(productInfo.product_price_id)
  }

  configureCustomizations()
}

const configureCustomizations = () => {
  if (data.chosenProduct?.customizations && data.chosenProduct?.customizations.length > 0) {
    data.customizations = data.chosenProduct.customizations.map(customization => {
      let customizationValue = null
      let options = customization.options.map(option => {
        // check if option.id is in data.options
        const index = data.options.findIndex(item => item.id === option.id)
        if (index !== -1 && customization.type === ProductCustomizationType.RADIO) {
          customizationValue = option.id
        }
        
        return {
          id: option.id,
          title: option.title,
          sub_title: option.sub_title,
          price: option.price,
          limit: option.limit,
          value: index !== -1 ? data.options[index].value : "",
          quantity: (index !== -1 ? data.options[index].quantity : 1) || option.quantity || 1,
          selected: index !== -1,
        }
      })

      return {
        id: customization.id,
        type: customization.type,
        title: customization.title,
        sub_title: customization.sub_title,
        options: options,
        value: customizationValue,
      }
    })
  }
}

const onSelectVariant = (variant, option) => {
  const index = data.variantCombination?.findIndex(item => item.id === variant.id)

  if (index === -1) {
    data.variantCombination.push({
      id: variant.id,
      name: variant.name,
      option: option,
    })
  } else {
    data.variantCombination[index].option = option
  }
}

const getChosenVariant = variant => {
  const index = data.variantCombination?.findIndex(item => item.id === variant.id)

  return index>-1 ? data.variantCombination[index].option : null
}

const isVariantDisabled = variant => {
  // Check variants before variant in data.chosenProduct.variants if they are selected
  if( data.chosenProduct.ignore_stock ) {
    return !data.chosenProduct.in_stock
  }

  const index = data.chosenProduct.variants.findIndex(item => item.id === variant.id)
  for (let i = 0; i < index; i++) {
    const item = data.chosenProduct.variants[i]
    const chosenVariant = getChosenVariant(item)
    if (!chosenVariant) {
      return true
    }
  }
}

const getVariantCombinationId = () => {
  return data.variantCombination
    .map(item => item.option.id)
    .sort((a, b) => a - b)
    .join("_")
}

const getIdOfVariantCombinationId = () => {
  if (!data.chosenProduct?.combination) {
    return null
  }
  const variantCombination = data.chosenProduct.combination[getVariantCombinationId()]
  if (variantCombination) {
    return variantCombination.id
  }
}

const getVariantCombinationById = id => {
  if (!id) {
    return
  }

  const key = Object.keys(data.chosenProduct.combination).find(key => {
    return data.chosenProduct.combination[key].id === id
  })

  const variantsIds = key.split("_")

  data.chosenProduct.variants.forEach(variant => {
    variant.variations.forEach(option => {
      // check if option.id is in variantsIds
      if (variantsIds.includes(option.id.toString())) {
        onSelectVariant(variant, option)
      }
    })
  })
}

const addProduct = () => {
  let product = {
    id: data.chosenProduct.id,
    name: data.chosenProduct.name,
    image: data.chosenProduct?.image,
    action: data.action,
    quantity: data.quantity,
    ignore_stock: data.ignore_stock,
    price: data.price,
    discounted_price: data.discounted_price,
    wholesale_price: data.wholesale_price,
    distribution_price: data.distribution_price,
    customizations: data.customizations,
    customization_price: customizationsPrice.value,
    product_price: { 
      variations: data.variantCombination.map(v => {
        return { variant: { name: v.name }, name: v.option?.name }
      }),
    },
    product_price_id: getIdOfVariantCombinationId(),
    store_branch_id: data.selectedBranch,
    options: [],
  }

  data.customizations.forEach(item => {
    if (item.type === ProductCustomizationType.CHECKBOX) {
      item.options.forEach(option => {
        if (option.selected) {
          product.options.push({
            id: option.id,
            quantity: option.quantity || 1,
            value: "",
            option: { id: option.id, title: option.title },
          })
        }
      })
    } else if (item.type === ProductCustomizationType.RADIO) {
      if (item.value) {
        let option = item.options.find(option => option.id === item.value)
        product.options.push({
          id: item.value,
          quantity: option.quantity || 1,
          value: "",
          option: { id: option.id, title: option.title },
        })
      }
    } else if (item.type === ProductCustomizationType.TEXT) {
      item.options.forEach(option => {
        if (option.value) {
          product.options.push({
            id: option.id,
            quantity: 1,
            value: option.value,
            option: { title: option.title },
          })
        }
      })
    }
  })

  confirmResolve(product)
  data.visible = false
}

let confirmResolve = value => {
  /* do nothing */
}

const cancel = () => {
  confirmResolve(false)
  data.visible = false
}

const openDialog = async (action, product) => {
  resetData()
  data.visible = true
  data.action = action

  if (action === 'create') {
    await setProductInfo(product.id, product.uuid)
  } else {
    await setProductInfo(product.id, product.uuid, product)
  }

  return new Promise(resolve => {
    confirmResolve = resolve
  })
}

const getPrice = product => {
  let price = product.discounted_price || product.price
  if (mprops.selectedUser?.id && mprops.selectedUser?.user_type === UserType.WHOLESALE && product.wholesale_price) {
    price = product.wholesale_price
  }
  else if (mprops.selectedUser?.id && mprops.selectedUser?.user_type === UserType.DISTRIBUTOR && product.distribution_price) {
    price = product.distribution_price
  }
  
  return price
}

const hasLineThroughPrice = data => {
  return data.discounted_price || 
    (mprops.selectedUser?.user_type===UserType.WHOLESALE && data.wholesale_price) || 
    (mprops.selectedUser?.user_type===UserType.DISTRIBUTOR && data.distribution_price)
}

// Watchers
watch(
  () => data.variantCombination,
  newVal => {
    if (newVal && newVal.length > 0) {
      // check variant combination stock
      // get variant combination id and sort them ex: 1_2_3
      const variantCombinationId = getVariantCombinationId()

      // get item in object of object data.chosenProduct.combination where  key = variantCombinationId
      if (data.chosenProduct?.combination && variantCombinationId) {
        const variantCombination = data.chosenProduct.combination[variantCombinationId]
        if (variantCombination) {
          if (data.chosenProduct.has_variations_stock) {
            if( data.chosenProduct.ignore_stock ) {
              data.stock = data.chosenProduct.in_stock ? unlimitedStock.value : 0
            } else {
              data.stock = variantCombination.quantity
            }
          }

          if (data.chosenProduct.has_variations_price) {
            data.price = variantCombination?.price
            data.discounted_price = variantCombination?.discounted_price
            data.wholesale_price = variantCombination?.wholesale_price
            data.distribution_price = variantCombination?.distribution_price
          }

          data.sku = variantCombination.sku
        }
      }
    } else if( data.chosenProduct ) {
      if( data.chosenProduct.ignore_stock ) {
        data.stock = data.chosenProduct.in_stock ? unlimitedStock.value : 0
      } else {
        data.stock = data.chosenProduct.quantity
      }

      data.image = data.chosenProduct?.image
      data.stockThreshold = data.chosenProduct.quantity_threshold
      data.price = data.chosenProduct?.price
      data.discounted_price = data.chosenProduct?.discounted_price
      data.wholesale_price = data.chosenProduct?.wholesale_price
      data.distribution_price = data.chosenProduct?.distribution_price
      data.sku = data.chosenProduct.sku
    }
  },
  { immediate: true, deep: true },
)

defineExpose({ openDialog })
</script>

<template>
  <BaseDialog
    v-if="data.visible"
    :title="title"
    max-width="920px"
    :disable-save-button="isAddButtonDisabled"
    :positive-btn-text="data.action === 'create' ? 'add' : 'update'"
    @save="addProduct"
    @close="cancel"
  >
    <template #content>
      <ProgressBar v-if="!data.step" />
      <VRow v-else>
        <VCol cols="4">
          <ProductImageSlider :images="productImages" />
        </VCol>
        <VCol cols="8">
          <VRow>
            <VCol cols="12">
              <h3>{{ data.chosenProduct.name }}</h3>
              <span
                v-if="data.chosenProduct?.brand?.name"
                :style="{ color: $vuetify.theme.current.colors['on-surface-one'] }"
                class="mt-1 d-block"
              >
                {{ t("by") + " " + data.chosenProduct?.brand?.name }}</span>
            </VCol>
            <VCol
              v-if="data.sku"
              cols="12"
            >
              <span
                style="font-size: 0.95rem; font-style: italic;"
                :style="{ color: $vuetify.theme.current.colors['on-surface-one'] }"
              >{{ t("sku") + ": " + data.sku }}</span>
            </VCol>
            <VCol
              v-if="data.chosenProduct.rating"
              cols="12"
              class="d-flex align-self-center ma-0 pa-0"
            >
              <VRating
                v-model="data.chosenProduct.rating.avg_rating"
                class="product-rating"
                color="yellow-darken-2"
                half-increments
                readonly
              />
              <span
                class="pa-2 text-blue-grey"
                style="font-size: 1.3rem;"
              >({{ data.chosenProduct.rating.avg_rating }})</span>
            </VCol>
            <VCol cols="12">
              <VDivider />
            </VCol>
            <VCol cols="auto">
              <h4>{{ t("price") + ":" }}</h4>
              <span
                v-if="hasLineThroughPrice(data)"
                :style="{ color: $vuetify.theme.current.colors['on-surface-one'] }"
                style="font-size: 1.5rem; text-decoration: line-through;"
                class="mr-2"
              >
                {{ formatPrice(data.price) }}
              </span>
              <span
                :style="{ color: $vuetify.theme.current.colors['on-surface'] }"
                style="font-size: 1.5rem;"
                class="mr-2"
              >
                {{ formatPrice(getPrice(data)) }}
              </span>
            </VCol>
            <VCol
              v-if="customizationsPrice"
              cols="auto"
              class="ml-10"
            >
              <h4 :style="{ color: $vuetify.theme.current.colors['success'] }">
                + {{ t("customizationPrice") + ":" }}
              </h4>
              <span
                :style="{ color: $vuetify.theme.current.colors['success'] }"
                style="font-size: 1.5rem;"
                class="mr-2"
              >
                {{ formatPrice(customizationsPrice) }}
              </span>
            </VCol>
            <VCol cols="12">
              <VRow>
                <VCol
                  v-for="(variant, index) in data.chosenProduct.variants"
                  :key="index"
                  cols="12"
                >
                  <h4>{{ variant.name + ":" }}</h4>
                  <ProductVariantSelector
                    :variants="variant.variations"
                    :chosen-variant="getChosenVariant(variant)"
                    :disabled="isVariantDisabled(variant)"
                    :out-of-stock-variants="data.outOfStockVariantCombinations"
                    @update:chosen-variant="onSelectVariant(variant, $event)"
                  />
                </VCol>
              </VRow>
            </VCol>
            <VCol cols="12">
              <VDivider />
            </VCol>
            <VCol
              v-if="data.chosenProduct?.customizations?.length > 0"
              cols="12 "
            >
              <h4
                :class="data.openCustomization ? 'mb-4' : ''"
                style="cursor: pointer;"
                @click="data.openCustomization = !data.openCustomization"
              >
                <VBtn
                  :icon="data.openCustomization ? 'mdi-minus' : 'mdi-plus'"
                  variant="text"
                  density="compact"
                />
                {{ t("customizations") + ":" }}
              </h4>
              <VExpandTransition>
                <VCard
                  v-show="data.openCustomization"
                  elevation="0"
                >
                  <VCardText class="py-0">
                    <VContainer>
                      <VRow>
                        <VCol
                          v-for="(customization, index) in data.customizations"
                          :key="index"
                          cols="12"
                          class="mb-3"
                        >
                          <div style="display: block;">
                            <h4
                              style=" font-weight: 600;text-transform: capitalize;"
                              class="text-on-surface-one"
                            >
                              {{ customization.title }}
                            </h4>
                            <span
                              v-if="customization.sub_title"
                              style="font-size: 0.9rem;"
                              :style="{ color: $vuetify.theme.current.colors['on-surface-two'] }"
                              class="mt-1 d-block"
                            >
                              {{ customization.sub_title }}
                            </span>
                          </div>
                          <VRow
                            v-if="customization.type === ProductCustomizationType.CHECKBOX"
                            class="mt-3"
                          >
                            <VCol
                              v-for="(option, idx) in customization.options"
                              :key="idx"
                              cols="12"
                              class="pa-0"
                            >
                              <VRow>
                                <VCol cols="6">
                                  <VCheckbox
                                    v-model="option.selected"
                                    :label="option.title"
                                    color="primary"
                                    density="comfortable"
                                    hide-details
                                  />
                                </VCol>
                                <VCol
                                  cols="2"
                                  class="align-self-center"
                                >
                                  <span
                                    class="text-success"
                                    style="font-size: 1rem;"
                                  >({{ formatPrice(option.price) }})</span>
                                </VCol>
                                <VCol
                                  cols="4"
                                  class="align-self-center"
                                >
                                  <PlusMinusField
                                    v-if="option.limit !== 1 && option.selected"
                                    v-model:value="option.quantity"
                                    :min="1"
                                    :max="option.limit || 100"
                                    :style-number="2"
                                  />
                                </VCol>
                              </VRow>
                            </VCol>
                          </VRow>
                          <VRow
                            v-else-if="customization.type === ProductCustomizationType.RADIO"
                            class="mt-3"
                          >
                            <VRadioGroup
                              v-model="customization.value"
                              inline
                            >
                              <VCol
                                v-for="(option, idx) in customization.options"
                                :key="idx"
                                cols="12"
                                class="pa-0"
                              >
                                <VRow>
                                  <VCol cols="6">
                                    <VRadio
                                      :value="option.id"
                                      :label="option.title"
                                      color="primary"
                                      density="comfortable"
                                      hide-details
                                      @click="customization.value === option.id ? (customization.value = null) : (customization.value = option.id)"
                                    />
                                  </VCol>
                                  <VCol
                                    cols="2"
                                    class="align-self-center"
                                  >
                                    <span
                                      class="text-success"
                                      style="font-size: 1rem;"
                                    >({{ formatPrice(option.price) }})</span>
                                  </VCol>
                                  <VCol
                                    cols="4"
                                    class="align-self-center"
                                  >
                                    <PlusMinusField
                                      v-if="option.limit !== 1 && customization.value === option.id"
                                      v-model:value="option.quantity"
                                      :min="1"
                                      :max="option.limit || 100"
                                      :style-number="2"
                                    />
                                  </VCol>
                                </VRow>
                              </VCol>
                            </VRadioGroup>
                          </VRow>
                          <VRow
                            v-else-if="customization.type === ProductCustomizationType.TEXT"
                            class="mt-3"
                          >
                            <VCol
                              v-for="(option, idx) in customization.options"
                              :key="idx"
                              cols="12"
                              class="pa-0"
                            >
                              <VRow>
                                <VCol
                                  v-if="option.price !== 0"
                                  :cols="1"
                                >
                                  <VCheckbox
                                    v-model="option.selected"
                                    color="primary"
                                    density="comfortable"
                                    hide-details
                                  />
                                </VCol>
                                <VCol :cols="option.price !== 0 ? 7 : 12">
                                  <TextField
                                    v-model="option.value"
                                    :label="option.title"
                                    color="primary"
                                    :disabled="option.price !== 0 && !option.selected"
                                    hide-details
                                    type="text"
                                  />
                                </VCol>
                                <VCol
                                  v-if="option.price !== 0"
                                  cols="4"
                                  class="align-self-center"
                                >
                                  <span
                                    class="text-success"
                                    style="font-size: 1rem;"
                                  >({{ formatPrice(option.price) }})</span>
                                </VCol>
                              </VRow>
                            </VCol>
                          </VRow>
                        </VCol>
                      </VRow>
                    </VContainer>
                  </VCardText>
                </VCard>
              </VExpandTransition>
            </VCol>
            <VCol
              v-if="data.chosenProduct?.customizations?.length > 0"
              cols="12"
            >
              <VDivider />
            </VCol>
            <VCol
              v-if="data.chosenProduct?.branches?.length > 0"
              cols="12"
            >
              <h4>
                {{ t("branches") }}
              </h4>
              <ProductBranchSelector
                :branches="data.chosenProduct?.branches"
                :chosen-branch="data.selectedBranch"
                @update:chosen-branch="data.selectedBranch = $event"
              />
            </VCol>
            <VCol
              v-if="data.chosenProduct?.branches?.length > 0"
              cols="12"
            >
              <VDivider />
            </VCol>
            <VCol cols="12">
              <VRow>
                <VCol
                  cols="auto"
                  class="mt-2 mr-5"
                >
                  <h4>
                    {{ t("stock") + ":" }}
                    <span
                      :style="{ color: data.stock > data.stockThreshold ? 'green' : 'red' }"
                    >{{ data.stock > 0 ? (data.stock==unlimitedStock ? t('inStock') : data.stock) : t("outOfStock") }}</span>
                  </h4>
                </VCol>
                <VCol cols="auto">
                  <PlusMinusField
                    v-model:value="data.quantity"
                    :min="1"
                    :max="data.stock"
                  />
                </VCol>
              </VRow>
            </VCol>
          </VRow>
        </VCol>
      </VRow>
    </template>
  </BaseDialog>
</template>

<style lang="scss">
.product-rating {
  .mdi-star::before {
    font-size: 36px;
  }

  .mdi-star-outline::before {
    font-size: 36px;
  }
}
</style>
