<script setup>
import { useHelperFunctions } from "@/composables/useHelperFunctions"
import NoImage from '@images/placeholder.png'
import i18n from "@/plugins/i18n"
import {
  avatarText,
} from '@core/utils/formatters'


const props = defineProps({
  mostUsersOrders: {
    type: Array,
    default: ()=>[],
  },
})

const { t } = i18n.global

const { formatPrice } = useHelperFunctions()
</script>

<template>
  <VCard :title="t('mostUsersOrders')">
    <VDivider />
    <VTable class="text-no-wrap">
      <thead>
        <tr>
          <th class="font-weight-semibold">
            {{ t('customer') }}
          </th>
          <th class="font-weight-semibold">
            {{ t('ordersCount') }}
          </th>
          <th class="font-weight-semibold">
            {{ t('ordersAmount') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="user in props.mostUsersOrders"
          :key="user.user_id"
        >
          <td style="padding-block: 0.61rem;">
            <VRow
              class="ma-2"
              dense
              align="center"
            >
              <VCol
                cols="auto"
              >
                <div class="text-medium-emphasis font-weight-semibold text-base">
                  <VAvatar
                    v-if="user.user.account.avatar"
                    size="34"
                    color="secondary"
                    variant="tonal"
                    :image="user.user?.account?.avatar?.thumb_url || NoImage"
                  />
                  <VAvatar
                    v-else
                    size="34"
                    color="secondary"
                    variant="tonal"
                  >
                    <span
                      class="font-weight-semibold"
                    >
                      {{ avatarText(user.user.account.name) }}
                    </span>
                  </VAvatar>
                </div>
              </VCol>
              <VCol cols="auto">
                <div class="text-sm">
                  {{ user.user.account?.first_name }} {{ user.user.account?.last_name }}
                </div>
              </VCol>
            </VRow>
          </td>
          <td style="padding-block: 0.61rem;">
            <div class="text-sm">
              {{ user.nb_orders }}
            </div>
          </td>
          <td style="padding-block: 0.61rem;">
            <div class="text-sm text-success">
              {{ formatPrice(user.final_amount) }}
            </div>
          </td>
        </tr>
      </tbody>
    </VTable>
  </VCard>
</template>
