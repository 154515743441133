<script setup>
import placeholder from '@images/placeholder.png'
import { useHelperFunctions } from "@/composables/useHelperFunctions"
import i18n from "@/plugins/i18n"

const mainProps = defineProps({
  popularProducts: {
    type: Array,
    default: ()=>[],
  },
})

const totalVisitors = computed(()=>{
  return mainProps.popularProducts.reduce((acc, product) => acc + product.nb_views, 0)
})

const { t } = i18n.global

const { formatPrice, truncate } = useHelperFunctions()
</script>

<template>
  <VCard
    :title="t('popularProducts')"
    :subtitle="t('totalVisitors', {visitors: totalVisitors})"
  >
    <VCardText>
      <VList class="card-list">
        <VListItem
          v-for="product in mainProps.popularProducts"
          :key="product.id"
        >
          <template #prepend>
            <VAvatar
              size="34"
              color="secondary"
              variant="tonal"
              :image="product.image?.thumb_url || placeholder"
            />
          </template>

          <VListItemTitle class="font-weight-medium">
            <VTooltip
              :disabled="product.name.length <= 15"
              max-width="300"
              location="top"
            >
              <template #activator="{ props }">
                <span
                  v-bind="props"
                >
                  {{ truncate(product.name, 15) }}
                </span>
              </template>
              <span>{{ product.name }}</span>
            </VTooltip>
          </VListItemTitle>
          <VListItemSubtitle>
            {{ product.store?.name }} / {{ formatPrice(product.price) }}
          </VListItemSubtitle>

          <template #append>
            <div class="d-flex align-center font-weight-semibold text-success">
              <span>{{ product.nb_views }} {{ t('views') }}</span>
            </div>
          </template>
        </VListItem>
      </VList>
    </VCardText>
  </VCard>
</template>

<style lang="scss" scoped>
.card-list {
  --v-card-list-gap: 19px;
}
</style>
