<template>
  <div class="d-block pb-1">
    <VLabel class="label-title pb-1">
      {{ props.title }}
    </VLabel>
    <span
      v-if="props.subtitle"
      class="label-subtitle"
    >{{ props.subtitle }}</span>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    required: false,
    default: () => (''),
  },
})
</script>

<style lang="scss" scoped>
.label-title {
  color: rgb(var(--v-theme-text-primary));
  font-family: "Roboto Flex", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  inline-size: 100%;
  line-height: 19px;
}

.label-subtitle {
  color: rgb(var(--v-theme-text-secondary));
  font-family: "Roboto Flex", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  inline-size: 100%;
  line-height: 16px;
}
</style>
