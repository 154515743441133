<script setup>
import placeholder from '@images/placeholder.png'
import { useHelperFunctions } from "@/composables/useHelperFunctions"
import i18n from "@/plugins/i18n"

const mainProps = defineProps({
  topSellingProducts: {
    type: Array,
    default: ()=>[],
  },
})

const { t } = i18n.global

const { formatPrice, truncate } = useHelperFunctions()
</script>

<template>
  <VCard
    :title="t('topSellingProducts')"
  >
    <VCardText>
      <VList class="card-list">
        <VListItem
          v-for="product in mainProps.topSellingProducts"
          :key="product.product.id"
        >
          <template #prepend>
            <VAvatar
              size="34"
              color="secondary"
              variant="tonal"
              :image="product.product.image?.thumb_url || placeholder"
            />
          </template>

          <VListItemTitle class="font-weight-medium">
            <VTooltip
              :disabled="product.product.name.length <= 15"
              max-width="300"
              location="top"
            >
              <template #activator="{ props }">
                <span
                  v-bind="props"
                >
                  {{ truncate(product.product.name, 15) }}
                </span>
              </template>
              <span>{{ product.product.name }}</span>
            </VTooltip>
          </VListItemTitle>
          <VListItemSubtitle>
            {{ product.product.store?.name }} / x{{ product.total_quantity }} {{ t('ordered') }}
          </VListItemSubtitle>

          <template #append>
            <div class="d-flex align-center font-weight-semibold text-success">
              <span>{{ formatPrice(product.product.price) }}</span>
            </div>
          </template>
        </VListItem>
      </VList>
    </VCardText>
  </VCard>
</template>

<style lang="scss" scoped>
.card-list {
  --v-card-list-gap: 19px;
}
</style>
