import { BaseUrl } from '@/helpers/BaseUrl'
import adminAxios from '@/plugins/axios'
import { defineStore } from "pinia"

const configBaseUrl = BaseUrl.get("config")
const webBaseUrl = BaseUrl.get("webPage")

export const useConfigStore = defineStore("config", {
  state: () => {
    return {}
  },
  getters: {},
  actions: {
    getConfigByKey(key='', sub_key='') {
      if( window.app_configs && (!key || window.app_configs[key]) ) {
        let val = key ? window.app_configs[key] : window.app_configs
        if( sub_key && key == 'config' ) {
          for(let k in val) {
            if( sub_key == val[k]?.key ) {
              return Array.isArray(val[k]?.data) ? [ ...val[k]?.data] : { ...val[k]?.data }
            }
          }
        }
        
        return [ ...val]
      }
      
      return []
    },
    setConfigByKey(key, data) {
      window.app_configs[key] = data

      return data
    },

    async getConfigBeforeLogin() {
      try {
        return await adminAxios.get(`${configBaseUrl}/config`)
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async getConfig() {
      try {
        const response = await adminAxios.get(`${configBaseUrl}/only-configs`)
        
        if( !window.app_configs ) {
          window.app_configs = {}
        }
        
        window.app_configs.config = response.data.data
        
        return response
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async getWebPage(params) {
      try {
        return await adminAxios.get(webBaseUrl, {params})
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async saveWebPage(payload) {
      try {
        return await adminAxios.post(webBaseUrl, payload)
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async saveConfigDetails(payload) {
      try {
        return await adminAxios.post(`${configBaseUrl}/save`, payload)
      } catch (error) {
        return await Promise.reject(error)
      }
    },
  },
})
