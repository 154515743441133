<template>
  <div class="product-image-slider">
    <Swiper
      :style="{
        '--swiper-navigation-color': $vuetify.theme.current.colors['on-surface'],
        '--swiper-pagination-color': $vuetify.theme.current.colors['on-surface'],
      }"
      :space-between="10"
      navigation
      :thumbs="{ swiper: thumbsSwiper }"
      :modules="[FreeMode, Navigation, Thumbs]"
      class="main-swiper"
    >
      <SwiperSlide
        v-for="(image, index) in props.images"
        :key="index"
      >
        <img
          :src="image"
          alt=""
        >
      </SwiperSlide>
    </Swiper>
    <Swiper
      :space-between="10"
      :slides-per-view="4"
      free-mode
      watch-slides-progress
      :modules="[FreeMode, Navigation, Thumbs]"
      class="gallery-swiper"
      @swiper="setThumbsSwiper"
    >
      <SwiperSlide
        v-for="(image, index) in props.images"
        :key="index"
        :class="{
          'swiper-slide-thumb-active': index === 0,
        }"
      >
        <img
          :src="image"
          alt=""
        >
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue"
import { FreeMode, Navigation, Thumbs } from "swiper"
import "swiper/css"
import "swiper/css/navigation"
import { ref } from "vue"

const props = defineProps({
  images: {
    type: Array,
    required: true,
  },
})

const thumbsSwiper = ref(null)

const setThumbsSwiper = swiper => {
  thumbsSwiper.value = swiper
}
</script>

<style lang="scss" scoped>
.main-swiper .swiper-wrapper {
  display: flex;
  align-items: center;
}

.product-image-slider {
  .swiper {
    block-size: 300px;
    inline-size: 100%;
    margin-inline: auto;
  }

  .swiper-slide {
    /* Center slide text vertically */
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(var(--v-theme-surface));
    font-size: 18px;
    text-align: center;
  }

  .main-swiper {
    block-size: 100%;
    inline-size: 100%;
  }

  .gallery-swiper {
    box-sizing: border-box;
    block-size: 20%;
    padding-block: 10px;
    padding-inline: 0;

    .swiper-slide {
      block-size: 100%;
      inline-size: 25%;
      opacity: 0.4;
    }

    .swiper-slide-thumb-active {
      opacity: 1;
    }
  }

  .swiper-slide img {
    display: block;
    border: thin solid rgb(var(--v-theme-stroke));
    border-radius: 5px;
    block-size: 100%;
    inline-size: 100%;
    object-fit: cover;
  }
}
</style>
