<template>
  <VAutocomplete
    variant="outlined"
    color="primary"
    :bg-color="$vuetify.theme.current.colors.surface"
    density="comfortable"
    v-bind="$attrs"
  >
    <template
      v-for="(x, slotName) in $slots"
      #[slotName]="item"
    >
      <slot
        :name="slotName"
        v-bind="item"
      />
    </template>
  </VAutocomplete>
</template>
