import { defineStore } from "pinia"

export const useSnackBarStore = defineStore("snackbar", {
  state: () => {
    return {
      idx: 0,
      notifications: [],
    }
  },
  getters: {
    getNotifications() {
      return this.notifications
    },
  },
  actions: {
    addNotification(notification) {
      if (notification.id === 0) {
        notification.id = this.idx++
      }
      this.notifications = [...this.notifications, notification]
    },
    removeNotification(id) {
      let index = -1
      for (let i = 0; i < this.notifications.length; i++) {
        if (this.notifications[i].id === id) {
          index = i
          break
        }
      }
      this.notifications.splice(index, 1)
    },

    success(message) {
      this.addNotification({
        id: 0,
        show: true,
        location: "bottom",
        icon: "mdi-check-circle",
        message,
        timeout: 3000,
        type: "success",
        color: "success",
      })
    },
    warn(message) {
      this.addNotification({
        id: 0,
        show: true,
        location: "bottom",
        icon: "mdi-alert-circle",
        message,
        timeout: 3000,
        type: "warning",
        color: "warning",
      })
    },
    error(message) {
      this.addNotification({
        id: 0,
        show: true,
        location: "bottom",
        icon: "mdi-alert-circle",
        message,
        timeout: 3000,
        type: "error",
        color: "error",
      })
    },
    responseError(error, callback = null) {
      if (error && error.data && error.data.error) {
        let message = [error.data.error.message]
        if (error.data.error.reason) {
          message.push(error.data.error.reason)
        }
        if (error.data.error.errors) {
          message.push(...error.data.error.errors)
        }
        this.error(message)

        if (callback) {
          callback()
        }

        return message
      }

      return ""
    },
  },
})
