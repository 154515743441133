<script setup>
import i18n from "@/plugins/i18n"

const props = defineProps({
  totalNew: {
    type: Number,
    default: 0,
  },
  totalIssue: {
    type: Number,
    default: 0,
  },
  totalOrder: {
    type: Number,
    default: 0,
  },
  totalCart: {
    type: Number,
    default: 0,
  },
})

const { t } = i18n.global
</script>

<template>
  <VCard>
    <VCardText>
      <h6 class="text-h6 text-no-wrap">
        {{ t('userOverview') }}
      </h6>
    </VCardText>

    <VCardText class="mt-2">
      <VRow>
        <VCol col="9">
          {{ t('newUsers') }}
        </VCol>
        <VCol
          col="3"
          class="text-right text-success"
        >
          {{ props.totalNew }}
        </VCol>
      </VRow>
      <VRow>
        <VCol col="9">
          {{ t('hasOrders') }}
        </VCol>
        <VCol
          col="3"
          class="text-right text-success"
        >
          {{ props.totalOrder }}
        </VCol>
      </VRow>
      <VRow>
        <VCol col="9">
          {{ t('hasCart') }}
        </VCol>
        <VCol
          col="3"
          class="text-right text-success"
        >
          {{ props.totalCart }}
        </VCol>
      </VRow>
      <VRow>
        <VCol col="9">
          {{ t('reportedIssue') }}
        </VCol>
        <VCol
          col="3"
          class="text-right text-success"
        >
          {{ props.totalIssue }}
        </VCol>
      </VRow>
    </VCardText>
  </VCard>
</template>
