<script setup>
import { themeConfig } from '@themeConfig'
const isDarkTheme = computed(()=>{
  return themeConfig.app.theme.value == 'dark'
})
</script>
<template>
  <VBtn
    :color="isDarkTheme ? 'white' : 'primary'"
    variant="flat"
    v-bind="$attrs"
  >
    <slot />
  </VBtn>
</template>

