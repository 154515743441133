import { useHelperFunctions } from "@/composables/useHelperFunctions"
import { lsGet, lsRemove, lsSet } from "@/helpers/localStorageHelper"
import router from "@/router"
import axios from "axios"

const { generateId } = useHelperFunctions()

const adminAxios = axios.create()

adminAxios.defaults.baseURL = import.meta.env.VITE_API_URL

adminAxios.interceptors.request.use(
  function (config) { 
    let supported_country_id = lsGet('supported_country_id')

    config.headers = {
      Authorization: "Bearer " + lsGet("accessToken"),
      'U-Country': supported_country_id || 0,
    }

    let device_id = lsGet("device_id")
    if( !device_id ) {
      device_id = generateId()
      lsSet("device_id", device_id)
    }

    config.params = {
      ...config.params,
      device_uid: device_id,
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)
adminAxios.interceptors.response.use(
  function (response) {
    return response
  },
  async function (error) {
    if (error?.response?.data?.error?.code === 401) {
      lsRemove([])
      await router.push({ name: "login" }).then(() => {
        location.reload()
      })
    }

    return Promise.reject(error.response)
  },
)
export default adminAxios
