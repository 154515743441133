<template>
  <VTooltip location="top">
    <template #activator="{ props }">
      <VBtn
        icon="mdi-square-edit-outline"
        color="warning"
        variant="text"
        density="compact"
        v-bind="$attrs"
      />
    </template>
    <span>{{ $t("edit") }}</span>
  </VTooltip>
</template>
