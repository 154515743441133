<template>
  <VRow>
    <VCol cols="12">
      <div class="variant-options">
        <VBtn
          v-for="variant in props.variants"
          :key="variant.name"
          class="option"
          :class="selectedVariant && variant.name === selectedVariant.name ? 'selected' : ''"
          variant="outlined"
          :disabled="isDisabled(variant)"
          @click="selectVariant(variant)"
        >
          <VIcon
            v-if="variant.value"
            :color="variant.value"
          >
            mdi-circle
          </VIcon>
          {{ variant.name }}
        </VBtn>
      </div>
    </VCol>
  </VRow>
</template>

<script setup>
import { computed } from "vue"

const props = defineProps({
  variants: {
    type: Array,
    required: true,
  },
  chosenVariant: {
    type: Object,
    default: () => {},
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  outOfStockVariants: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits(["update:chosenVariant"])

const selectedVariant = computed({
  get: () => props.chosenVariant,
  set: value => {
    emit("update:chosenVariant", value)
  },
})

const selectVariant = variant => {
  selectedVariant.value = variant
}

const isDisabled = variant => {
  return !!props.disabled
}
</script>

<style lang="scss" scoped>
.variant-options {
  .option {
    padding: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    margin-block: 0.5rem;
    margin-inline: 0 0.5rem;
    transition: all 0.3s ease-in-out;

    &.selected {
      background-color: rgba(var(--v-theme-primary), 0.8);
      color: rgb(var(--v-theme-on-primary)) !important;
    }

    &.color {
      padding: 0;
      border-radius: 50%;
      margin: 0;
      block-size: 2rem;
      inline-size: 2rem;

      &.selected {
        border: 1px solid rgb(var(--v-theme-stroke));
      }

      .option-content {
        border-radius: 50%;
        block-size: 100%;
        inline-size: 100%;
      }
    }
  }
}

.variant-radio-buttons-color-selector .option {
  display: inline-block;
  border: 4px solid rgba(var(--v-theme-primary), 0.15);
  border-radius: 50%;
  cursor: pointer;
  margin-block-start: 10px;
  margin-inline-end: 15px;
}

.variant-radio-buttons-color-selector .option:hover {
  cursor: pointer;
}

.variant-radio-buttons-color-selector .option .option-content {
  border: 1px solid rgba(var(--v-theme-primary), 0.15);
  border-radius: 50%;
  margin: 3px;
  block-size: 26px;
  inline-size: 26px;
}

.variant-radio-buttons-color-selector .option.selected {
  border: 4px solid rgba(var(--v-theme-primary), 0.9);
}
</style>
