import { lsGet } from "@/helpers/localStorageHelper"
import moment from "moment"

export const useHelperFunctions = () => {
  const generateId = () => {
    let result = ""
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    let charactersLength = characters.length
    for (let i = 0; i < 15; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
  
    return result
  }

  const humanReadableDate = date => {
    return moment.duration(moment().diff(moment(date))).humanize()
  }

  const truncate = (text, length, clamp) => {
    if(!text) return
    clamp = clamp || '...'
    var node = document.createElement('div')
    node.innerHTML = text
    var content = node.textContent
    
    return content.length > length ? content.slice(0, length) + clamp : content
  }

  const formatOnlyDate = value => {
    if (value) {
      return moment(String(value), "YYYY-MM-DD HH:mm:ss").add(moment().utcOffset(), "minutes").format("dddd MMM, DD YYYY")
    }
    
    return ''
  }

  const formatDate = (value, fullWithLineBreak = false) => {
    if (value) {
      if (fullWithLineBreak) {
        const date = moment(value, "YYYY-MM-DD HH:mm:ss").add(moment().utcOffset(), "minutes")
        let string = date.format("dddd MMM, DD YYYY")
        string += ` <span class="grey--text">${date.format("<br> hh:mm a")}</span>`
        
        return string
      }
      
      return moment(String(value), "YYYY-MM-DD HH:mm:ss").add(moment().utcOffset(), "minutes").format("DD/MM/YYYY hh:mm a")
    }
  }

  const formatDateTime = value => {
    // format 2023-01-27 09:31:36 to  Starts 30 Jul, 2023 12:00 AM
    if (value) {
      return moment(String(value), "YYYY-MM-DD HH:mm:ss").add(moment().utcOffset(), "minutes").format("DD MMM, YYYY hh:mm A")
    }
  }

  // function for size format
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const toCamelCase = str => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase()
      })
      .replace(/\s+/g, "")
  }

  const toPascalCase = str => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word) {
        return word.toUpperCase()
      })
      .replace(/\s+/g, "")
  }

  const is_numeric = str => {
    return /^\d+$/.test(str)
  }

  const formatPrice = (amount, currency = '', rate = 1, compact=true) => {
    // convert amount to number
    amount = amount ? Number(amount) * rate : 0
    let opts = {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    }
    if (compact) {
      opts.notation = 'compact'
    }
    const formatter = new Intl.NumberFormat('en-US', opts)

    return formatter.format(amount).replace('$', '').replace('USD', '') + ' ' + (currency || lsGet('currency') || '$')
  }

  const generateDeviceId = () => {
    return URL.createObjectURL(new Blob()).substr(-36)
  }

  const groupDashboardDataByMonth = (data, name, name2) => {
    // Group data by Month name
    let groupedData = {}
    for (let key in data) {
      let month = moment(key).format("YYYY-MM")
      if (groupedData[month]) {
        groupedData[month].total1 += parseFloat(data[key][name.toString()])
        groupedData[month].total2 += parseFloat(data[key][name2.toString()])
      } else {
        groupedData[month] = {
          total1: parseFloat(data[key][name.toString()]),
          total2: parseFloat(data[key][name2.toString()]),
        }
      }
    }
    
    return groupedData
  }

  const isDateExpired = date => {
    return moment().isAfter(date)
  }

  const isDateStarted = date => {
    return moment().isAfter(date)
  }

  const generateRandomString = length => {
    let result = ""
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    const charactersLength = characters.length

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }

    return result
  }

  return {
    generateId,
    truncate,
    humanReadableDate,
    formatDateTime,
    formatDate,
    formatOnlyDate,
    formatBytes,
    toCamelCase,
    toPascalCase,
    is_numeric,
    formatPrice,
    generateDeviceId,
    groupDashboardDataByMonth,
    isDateExpired,
    isDateStarted,
    generateRandomString,
  }
}
