<template>
  <VDialog
    v-model="show"
    :width="props.maxWidth || 'auto'"
    min-width="350"
    :transition="props.transition"
    :persistent="props.persistent"
  >
    <VCard class="bg-card-background">
      <VCardTitle
        class="d-flex bg-surface pl-7 pa-2 bg-primary fix-card-header"
      >
        <span class="align-self-center">{{ $t(props.title) }}
          <VChip
            v-if="props.count !== null"
            small
          >
            {{ props.count }}
          </VChip>
        </span>
        <VSpacer />
        <VBtn
          color="grey"
          variant="plain"
          size="small"
          icon="mdi-close"
          @click="close"
        />
      </VCardTitle>
      <VCardText class="mt-5 pt-0">
        <slot name="content" />
      </VCardText>
      
      <VCardActions
        v-if="props.enableCustomButtons"
        class="justify-end fix-card-footer"
      >
        <slot name="dialog-buttons" />
      </VCardActions>
      <VCardActions
        v-else-if="props.enableCloseButton || props.enableSaveButton"
        class="justify-end fix-card-footer"
      >
        <CancelButton
          v-if="props.enableCloseButton"
          color="error"
          :disabled="props.loading"
          @click="close"
        >
          {{ t(props.negativeBtnText) }}
        </CancelButton>
        <VSpacer />
        <PrimaryButton
          v-if="props.enableSaveButton"
          :disabled="props.disableSaveButton"
          :loading="props.loading"
          @click="save"
        >
          {{ t(props.positiveBtnText) }}
        </PrimaryButton>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script setup>
import CancelButton from "@/components/button/CancelButton.vue";
import PrimaryButton from "@/components/button/PrimaryButton.vue";
import i18n from "@/plugins/i18n";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  count: {
    type: Number,
    default: null,
  },
  enableCloseButton: {
    type: Boolean,
    default: true,
  },
  enableSaveButton: {
    type: Boolean,
    default: true,
  },
  disableSaveButton: {
    type: Boolean,
    default: false,
  },
  maxWidth: {
    type: [Number, String],
    default: 0,
  },
  transition: {
    type: String,
    default: "fade-transition",
  },
  persistent: {
    type: Boolean,
    default: true,
  },
  enableCustomButtons: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  positiveBtnText: {
    type: String,
    default: "save",
  },
  negativeBtnText: {
    type: String,
    default: "cancel",
  },
})

const emit = defineEmits(["close", "save"])

const { t } = i18n.global

const show = true

const close = () => {
  emit("close")
}

const save = () => {
  emit("save")
}
</script>
