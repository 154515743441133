<template>
  <VAlert
    v-show="visible"
    :title="props.title"
    :color="color"
    :icon="icon"
    border="start"
    density="compact"
    v-bind="$attrs"
  >
    <ul
      v-if="props.list.length > 0"
      class="pl-4"
    >
      <li
        v-for="(item, index) in props.list"
        :key="index"
      >
        {{ item }}
      </li>
    </ul>
  </VAlert>
</template>

<script setup>
const props = defineProps({
  visible: { type: Boolean, required: true },
  title: { type: String, required: true },
  color: { type: String, required: true },
  icon: { type: String, required: true },
  closable: { type: Boolean, default: true, required: false },
  list: { type: Array, default: () => [], required: false },
})
</script>

<style>
.v-alert .v-alert-title {
  font-size: 0.9rem !important;
  line-height: 1.25rem;
  padding-block-end: 5px;
}

.v-alert .v-alert__content {
  font-size: 0.8rem !important;
}
</style>
