<template>
  <VRow>
    <VCol cols="12">
      <VRow>
        <VCol
          cols="auto"
          class="align-self-center"
        >
          <h3 v-if="props.title">
            {{ props.title }}
            <VChip small>
              {{ itemsList ? itemsList.length : 0 }}
            </VChip>
          </h3>
          <span
            v-if="props.subtitle"
            class="subtitle"
          >{{ props.subtitle }}</span>
        </VCol>
        <VSpacer />
        <VCol cols="auto">
          <FilterSelector 
            :value="data.filtersData.brand_id"
            :items="data.brands"
            icon="mdi-babel"
            :loading="data.loadingBrands"
            @change-value="data.filtersData.brand_id = $event"
          />
        </VCol>
        <VCol cols="auto">
          <FilterSelector 
            :value="data.filtersData.category_id"
            :items="data.categories"
            icon="mdi-shape"
            :loading="data.loadingCategories"
            @change-value="data.filtersData.category_id = $event"
          />
        </VCol>
      </VRow>
      <VRow>
        
        <VCol
          class="d-flex justify-end align-self-center"
          style="position: relative;"
        >
          <Autocomplete
            v-model="itemsList"
            v-model:search="itemSearchQuery"
            prepend-inner-icon="mdi-magnify"
            class="search-item"
            :placeholder="props.searchPlaceholder"
            :items="data.searchItems"
            multiple
            no-filter
            return-object
            :hide-selected="props.hideSelected"
            hide-details
            :loading="itemsLoading"
            :no-data-text="itemsLoading ? t('loadingdots') : $vuetify.noDataText"
            @keyup="onKeyUp"
          >
            <template
              v-for="(x, slotName) in $slots"
              #[slotName]="item"
            >
              <slot
                :name="slotName"
                v-bind="item"
              />
            </template>
          </Autocomplete>
        </VCol>
      </VRow>
    </VCol>
    <VCol cols="12">
      <VTable>
        <thead>
          <tr>
            <th
              v-for="(header, index) in props.headers"
              :key="index"
              class="text-start"
            >
              {{ header.name }}
            </th>
            <th v-if="props.enableDelete" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(it, index) in itemsList"
            :key="index"
          >
            <td
              v-for="(header, id) in props.headers"
              :key="id"
              class="text-start"
            >
              <template v-if="header.editable && (header.type === 'text' || header.type === 'number')">
                <TextField
                  v-model="it[header.key]"
                  :type="header.type"
                  :suffix="header.suffixRelation ? getSymbol(it, header.suffixRelation) : ''"
                  variant="outlined"
                  density="compact"
                  hide-details
                />
              </template>
              <template v-else-if="header.editable && header.type === 'select'">
                <VSelect
                  v-model="it[header.key]"
                  :items="header.options"
                  item-title="name"
                  item-value="value"
                  variant="outlined"
                  density="compact"
                  hide-details
                />
              </template>
              <template v-else-if="header.editable && header.type === 'select-product-variant'">
                <VSelect
                  v-model="it[header.key]"
                  :items="header.options"
                  item-title="name"
                  item-value="id"
                  variant="outlined"
                  density="compact"
                  hide-details
                />
              </template>
              <template v-else-if="header.key === 'price'">
                {{ formatPrice(it.sell_price) }}
              </template>
              <template v-else-if="header.type === 'product-image'">
                <VImg
                  :src="it[header.key]?.thumb_url || DefaultProductPicture"
                  width="64px"
                  height="64px"
                  class="border-sm rounded-circle"
                  cover
                />
              </template>
              <template v-else>
                {{ (header.suffix ? header.suffix + " " : "") + it[header.key] }}
              </template>
            </td>
            <td
              v-if="props.enableDelete || props.enableEdit"
              class="text-start"
            >
              <DeleteButton
                v-if="props.enableDelete"
                @click="onDeleteItem(index)"
              />
              <EditButton
                v-if="props.enableEdit"
                @click="onEditItem(index)"
              />
            </td>
          </tr>
        </tbody>
      </VTable>
    </VCol>
  </VRow>
  <ProductDialog
    v-if="props.withVariations"
    ref="productDialog"
    :selected-user="selectedUser"
  />
</template>

<script setup>
import DeleteButton from "@/components/button/DeleteButton.vue"
import EditButton from "@/components/button/EditButton.vue"
import ProductDialog from "@/components/dialogs/ProductDialog.vue"
import TextField from "@/components/fields/TextField.vue"
import Autocomplete from "@/components/selectors/Autocomplete.vue"
import FilterSelector from "@/components/selectors/FilterSelector.vue"
import { useHelperFunctions } from "@/composables/useHelperFunctions"
import UserType from "@/constants/UserType"
import i18n from "@/plugins/i18n"
import { useConfigStore } from "@/stores/config"
import { useProductStore } from "@/stores/product"
import DefaultProductPicture from '@images/placeholder.png'
import { computed, reactive, ref } from "vue"
import { lsGet } from "../../helpers/localStorageHelper"

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: "",
  },
  subtitle: {
    type: String,
    required: false,
    default: "",
  },
  headers: {
    type: Array,
    required: true,
  },
  items: {
    type: Array,
    required: true,
  },
  enableDelete: {
    type: Boolean,
    required: false,
    default: false,
  },
  enableEdit: {
    type: Boolean,
    required: false,
    default: false,
  },
  searchPlaceholder: {
    type: String,
    required: false,
    default: "",
  },
  data: {
    type: Object,
    required: false,
    default: () => {},
  },
  withVariations: {
    type: Boolean,
    required: false,
    default: false,
  },
  extraParams: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  hideSelected: {
    type: Boolean,
    required: false,
    default: true,
  },
  selectedUser: {
    type: Object,
    required: false,
    default: () => ({}),
  },
})

const emit = defineEmits(["addItem", "updateItem", "searchItems", "deleteItem"])

const { t } = i18n.global

const { formatPrice } = useHelperFunctions()

const product = useProductStore()
const config  = useConfigStore()

const itemSearchQuery = ref('')
const itemsTimer = ref(null)
const itemsLoading = ref(false)

const data = reactive({
  searchItems: [],
  loadingBrands: false,
  brands: [],
  loadingCategories: false,
  categories: [],
  filtersData: {
    brand_id: 0,
    category_id: 0,
  },
})

const productDialog = ref(null)

const itemsList = computed({
  get() {
    return props.items
  },
  set(newItem) {
    data.searchItems = []
    if (props.withVariations) {
      productDialog.value.openDialog('create', newItem[newItem.length - 1]).then(selectedProduct => {
        if (selectedProduct) {
          let sell_price = getPrice(selectedProduct)
          emit("addItem", {
            ...selectedProduct,
            ...props.data,
            ...{
              price: selectedProduct.price,
              discounted_price: selectedProduct.discounted_price,
              wholesale_price: selectedProduct.wholesale_price,
              distribution_price: selectedProduct.distribution_price,
              customization_price: selectedProduct.customization_price,
              sell_price: sell_price,
              total_price: (sell_price + selectedProduct.customization_price) * selectedProduct.quantity,
            },
          })
        }
      })
    } else {
      emit("addItem", {
        ...newItem[newItem.length - 1],
        ...props.data,
      })
    }
  },
})

onMounted(async () => {
  data.userData = lsGet('userData', true) || null

  data.brands = [{ id: 0, name: t('all') }]
  data.categories = [{ id: 0, name: t('all') }]

  let cached_brands = config.getConfigByKey('brands')
  data.brands = [{ id: 0, name: t('all') }, ...cached_brands]

  let cached_categories = config.getConfigByKey('categories')
  data.categories = [{ id: 0, name: t('all') }, ...cached_categories]
  
})

const onDeleteItem = index => {
  emit("deleteItem", index)
}

const getPrice = (product) => {
  console.log(product)
  let price = product.discounted_price || product.price
  if (props.selectedUser?.id && props.selectedUser?.user_type === UserType.WHOLESALE && product.wholesale_price) {
    price = product.wholesale_price
  }
  else if (props.selectedUser?.id && props.selectedUser?.user_type === UserType.DISTRIBUTOR && product.distribution_price) {
    price = product.distribution_price
  }
  return price
}

const onEditItem = index => {
  if (props.withVariations) {
    productDialog.value.openDialog('edit', props.items[index]).then(selectedProduct => {
      if (selectedProduct) {
        emit("updateItem", index, {
          ...selectedProduct,
          ...props.data,
          ...{
            price: selectedProduct.price,
            discounted_price: selectedProduct.discounted_price,
            wholesale_price: selectedProduct.wholesale_price,
            distribution_price: selectedProduct.distribution_price,
            customization_price: selectedProduct.customization_price,
            total_price: (getPrice(selectedProduct) + selectedProduct.customization_price) * selectedProduct.quantity,
          },
        })
      }
    })
  }
}

const getSymbol = (vl, key) => {
  let header = props.headers.find(x => x.key === key)
  let chosen = header.options.find(x => x.value === vl[key])
  if (!chosen) return ""
  
  return chosen.suffix
}

function onKeyUp($event) {
  if (itemsTimer.value) {
    clearTimeout(itemsTimer.value)
  }

  if( $event.keyCode==13 ) {
    onSearch()
    
    return
  }

  itemsTimer.value = setTimeout(async() => {
    onSearch()
  }, 700)
}

async function onSearch () {
  itemsLoading.value = true

  let params = {
    search: itemSearchQuery.value,
    page: 1,
    per_page: 10,
    sort: "name",
    descending: false,
  }
  
  if( data.filtersData.brand_id ) {
    params.brand_id = data.filtersData.brand_id
  }
  
  if( data.filtersData.category_id ) {
    params.category_id = data.filtersData.category_id
  }

  if (props.extraParams) {
    params = { ...params, ...props.extraParams }
  }

  data.searchItems = []
  await product.getProductsList(params)
    .then(res => {
      if (props.hideSelected && props.items.length > 0 && res.length > 0) {
        data.searchItems = res.data.data.filter(x => !props.items.find(y => y.id === x.id))
      } else {
        data.searchItems = res.data.data
      }
    })
    .finally(() => itemsLoading.value = false)
}
</script>

<style lang="scss">
.search-item .v-autocomplete__selection {
  display: none !important;
}
</style>
