import i18n from "@/plugins/i18n";
import moment from "moment";

const { t } = i18n.global
export default Object.freeze({
  ALL: { label: t("all"), value: [moment('2000-01-01').toDate(), moment('2100-01-01').toDate()] },
  TODAY: { label: t("today"), value: [moment().startOf('day').toDate(), moment().endOf('day').toDate()] },
  YESTERDAY: { label: t("yesterday"), value: [moment().startOf('day').subtract(1, 'day').toDate(), moment().endOf('day').subtract(1, 'day').toDate()] },
  LAST_7_DAYS: { label: t("lastSevenDays"), value: [moment().startOf('day').subtract(7, 'day').toDate(), moment().endOf('day').toDate()] },
  LAST_30_DAYS: { label: t("lastThirtyDays"), value: [moment().startOf('day').subtract(30, 'day').toDate(), moment().endOf('day').toDate()] },
  LAST_2_MONTHS: { label: t("lastTwoMonths"), value: [moment().startOf('month').subtract(2, 'month').toDate(), moment().endOf('day').endOf('month').subtract(1, 'month').toDate()] },
  LAST_3_MONTHS: { label: t("lastThreeMonths"), value: [moment().startOf('month').subtract(3, 'month').toDate(), moment().endOf('day').endOf('month').subtract(1, 'month').toDate()] },
  LAST_6_MONTHS: { label: t("lastSixMonths"), value: [moment().startOf('month').subtract(6, 'month').toDate(), moment().endOf('day').endOf('month').subtract(1, 'month').toDate()] },
  LAST_12_MONTHS: { label: t("lastTwelveMonths"), value: [moment().startOf('month').subtract(12, 'month').toDate(), moment().endOf('day').endOf('month').subtract(1, 'month').toDate()] },
})
