<script setup>
const props = defineProps({
  vmodel: {
    type: [Boolean, Object],
    default: false,
  },
  width: {
    type: Number,
    default: 6,
  },
  size: {
    type: Number,
    default: 66,
  },
  indeterminate: {
    type: Boolean,
    default: true,
  },
  className: {
    type: String,
    default: 'ma-10',
  },
})
</script>

<template>
  <VRow
    :class="'fill-height ' + props.className"
    align="center"
    justify="center"
  >
    <VProgressCircular
      color="primary"
      :v-model="props.vmodel"
      :indeterminate="props.indeterminate"
      :size="props.size"
      :width="props.width"
    />
  </VRow>
</template>
