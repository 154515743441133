<template>
  <PrimaryButton
    prepend-icon="mdi-plus"
    variant="outlined"
    color="primary"
  >
    {{ t("addNew") }}
  </PrimaryButton>
</template>

<script setup>
import PrimaryButton from "@/components/button/PrimaryButton.vue"
import i18n from "@/plugins/i18n"

const { t } = i18n.global
</script>
