<template>
  <VAutocomplete
    v-model="value"
    :items="parentProps.items"
    :item-value="parentProps.itemValue || 'id'" 
    :item-title="parentProps.itemTitle || 'name'"
    :prefix="is_focused.value || value || !parentProps.prefix ? '' : $t(parentProps.prefix)"
    variant="outlined"
    density="compact"
    color="primary"
    class="rounded-b"
    style="min-width: 220px;"
    :bg-color="$vuetify.theme.current.colors.surface"
    :prepend-inner-icon="parentProps.icon"
    hide-details
    :multiple="parentProps.multiple"
    :loading="parentProps.loading"
    :placeholder="parentProps.placeholder"
    :no-data-text="parentProps.loading ? $t('loadingdots') : $vuetify.noDataText"
    @update:focused="onFocusInput"
  >
    <template #item="{ props, item }">
      <!-- Default content if no scoped slot is provided by the parent -->
      <VListItem
        v-bind="props"
      >
        <template #prepend>
          <VAvatar
            v-if="item?.raw?.image?.base_url"
            variant="tonal"
            size="45"
            class="mr-2"
          >
            <VImg
              :src="item?.raw?.image?.base_url + '/' + item?.raw?.image?.webp_thumbnail"
            />
          </VAvatar>
          <VIcon
            v-else-if="parentProps.icon"
            :icon="parentProps.icon"
            size="35"
          />
        </template>
        <template #title>
          {{ item?.raw[parentProps.itemTitle || 'name'] }}
        </template>
      </VListItem>
    </template>
  </VAutocomplete>
</template>

<script setup>
import { computed } from "vue"

const parentProps = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  value: {
    type: [Number, String, Array],
    required: false,
  },
  prefix: {
    type: String,
    default: () => '',
  },
  items: {
    type: Array,
    required: true,
  },
  icon: {
    type: String,
    required: false,
    default: '',
  },
  itemTitle: {
    type: String,
    required: false,
    default: 'name',
  },
  itemValue: {
    type: String,
    required: false,
    default: 'id',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(["changeValue"])

const value = computed({
  get: () => parentProps.value,
  set: val => emit("changeValue", val),
})

const is_focused = ref(false)

const onFocusInput = focused => {
  is_focused.value = focused
}
</script>
