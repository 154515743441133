<script setup>
import { useHelperFunctions } from "@/composables/useHelperFunctions"
import i18n from "@/plugins/i18n"
import { hexToRgb } from '@layouts/utils'
import VueApexCharts from 'vue3-apexcharts'
import { useTheme } from 'vuetify'

const props = defineProps({
  yearlyGrowth: {
    type: Array,
    default: ()=>[],
  },
})

const { t } = i18n.global
const vuetifyTheme = useTheme()
const currentTab = ref(0)
const refVueApexChart = ref()
const { formatPrice } = useHelperFunctions()

const chartConfigs = computed(() => {
  const currentTheme = vuetifyTheme.current.value.colors
  const variableTheme = vuetifyTheme.current.value.variables
  const labelPrimaryColor = `rgba(${ hexToRgb(currentTheme.primary) },${ variableTheme['dragged-opacity'] })`
  const legendColor = `rgba(${ hexToRgb(currentTheme['on-background']) },${ variableTheme['high-emphasis-opacity'] })`
  const borderColor = `rgba(${ hexToRgb(String(variableTheme['border-color'])) },${ variableTheme['border-opacity'] })`
  const labelColor = `rgba(${ hexToRgb(currentTheme['on-surface']) },${ variableTheme['disabled-opacity'] })`

  const barColors = [
    labelPrimaryColor,
    labelPrimaryColor,
    labelPrimaryColor,
    labelPrimaryColor,
    labelPrimaryColor,
    labelPrimaryColor,
    labelPrimaryColor,
    labelPrimaryColor,
    labelPrimaryColor,
    labelPrimaryColor,
    labelPrimaryColor,
    labelPrimaryColor,
  ]

  barColors[(new Date()).getMonth()] = currentTheme.primary

  return {
    chartOptions: {
      chart: {
        parentHeightOffset: 0,
        type: 'bar',
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          columnWidth: '32%',
          startingShape: 'rounded',
          borderRadius: 4,
          distributed: true,
          dataLabels: { position: 'top' },
        },
      },
      grid: {
        show: false,
        padding: {
          top: 0,
          bottom: 0, 
          left: -10,
          right: -10,
        },
      },
      colors: barColors,
      dataLabels: {
        enabled: true,
        formatter(val) {
          return formatPrice(val)
        },
        offsetY: -25,
        style: {
          fontSize: '12px',
          colors: [legendColor],
          fontWeight: '600',
          fontFamily: 'Public Sans',
        },
      },
      legend: { show: false },
      tooltip: { enabled: false },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Dec',
        ],
        axisBorder: {
          show: true,
          color: borderColor,
        },
        axisTicks: { show: false },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
            fontFamily: 'Public Sans',
          },
        },
      },
      yaxis: {
        labels: {
          offsetX: -15,
          formatter(val) {
            return formatPrice(val)
          },
          style: {
            fontSize: '12px',
            colors: labelColor,
            fontFamily: 'Public Sans',
          },
          min: 0,
          max: 60000,
          tickAmount: 6,
        },
      },
      responsive: [
        {
          breakpoint: 1441,
          options: { plotOptions: { bar: { columnWidth: '41%' } } },
        },
        {
          breakpoint: 590,
          options: {
            plotOptions: { bar: { columnWidth: '61%' } },
            yaxis: { labels: { show: false } },
            grid: {
              padding: {
                right: 0,
                left: -20,
              },
            },
            dataLabels: {
              style: {
                fontSize: '12px',
                fontWeight: '400',
              },
            },
          },
        },
      ],
    },
    series: [{
      data: props.yearlyGrowth,
    }],
  }
})
</script>

<template>
  <VCard
    :title="t('earningReports')"
    :subtitle="t('yearlyEarningsOverview')"
  >
    <VCardText>
      <VueApexCharts
        ref="refVueApexChart"
        :key="currentTab"
        :options="chartConfigs.chartOptions"
        :series="chartConfigs.series"
        height="300"
        class="mt-3"
      />
    </VCardText>
  </VCard>
</template>
