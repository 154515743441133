<script setup>
import i18n from "@/plugins/i18n";

const props = defineProps({
  title: {
    type:String,
    default: "Dialog Title",
  },
  positiveBtnText: {
    type: String,
    default: "save",
  },
  negativeBtnText: {
    type: String,
    default: "cancel",
  },
  positiveBtnColor: {
    type: String,
    default: "primary",
  },
  negativeBtnColor: {
    type: String,
    default: "secondary",
  },
  negativeLoading: {
    type: Boolean,
    default: false,
  },
  positiveLoading: {
    type: Boolean,
    default: false,
  },
  width: {
    type: [Number, String],
    default: 'auto',
  },
})

const emit = defineEmits(["negativeAction", "positiveAction"])

const { t } = i18n.global

const data = ref({
  show: true,
})

function positive() {
  emit("positiveAction")
}

function negative() {
  emit("negativeAction")
}
</script>

<template>
  <VDialog
    v-model="data.show"
    :width="props.width"
    persistent
  >
    <VCard>
      <VToolbar
        color="primary"
        class="elevation-0 fix-card-header"
      >
        <VBtn
          icon
          @click="negative"
        >
          <VIcon color="white">
            mdi-close
          </VIcon>
        </VBtn>
        <VToolbarTitle>{{ props.title }}</VToolbarTitle>
        <VSpacer />
      </VToolbar>
      <VContainer>
        <slot name="content" />
      </VContainer>
      
      <VDivider class="mb-2" />
      
      <VCardActions class="mt-1 fix-card-footer">
        <VRow no-gutters>
          <VCol class="text-center">
            <VBtn
              :color="props.negativeBtnColor"
              text
              :loading="props.negativeLoading"
              @click="negative"
            >
              {{ t(props.negativeBtnText) }}
            </VBtn>
          </VCol>
          <VCol class="text-center">
            <VBtn
              :color="props.positiveBtnColor"
              text
              :loading="props.positiveLoading"
              @click="positive"
            >
              {{ t(props.positiveBtnText) }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
